import React, { FC, useState } from 'react'
import { useTranslation } from 'next-i18next'
import {
  useGetPermissionsDataV1GovPermissionsGet,
  useLedgerOutlineDataV1GovLedgerOutlineGet,
  useLedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet,
} from '~/libs/apis/data/gov/gov'
import Box from '~/components/Box/Box'
import { SkeletonComponent } from '@syncfusion/ej2-react-notifications'
import LedgerInfoTable from '~/templates/place/detail/building/LedgerInfoTable'
import TabButton from '~/components/TabButton/TabButton'
import ScrollableTabButtonBox from '~/components/TabButton/ScrollableTabButtonBox'
import Typography from '~/components/Typography/Typography'
import Button from '~/components/Button/Button'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'
import { Separated } from '@toss/react'
import DataSourceUpdate from '~/components/DataSource/DataSourceUpdate'
import { subMonths, format } from 'date-fns'
import { PermissionOut } from '~/libs/apis/data/model'
import { formatDateYmd } from '~/libs/utils/date'
import useCommonCode from '~/libs/hooks/useCommonCode'
import Nodata from '~/components/NoData'

interface LandDetailTableProps {
  pnu: string
}

const lastYear = subMonths(new Date(), 11)

const LandDetailTable: FC<LandDetailTableProps> = ({ pnu }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: msg } = useTranslation('common', { keyPrefix: 'common_msg' })
  const { t: detail } = useTranslation('common', { keyPrefix: 'detail.common_data' })
  const { data: ledger } = useLedgerOutlineDataV1GovLedgerOutlineGet({ pnu: pnu })
  const { data: ledgerSummary } = useLedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet(
    { pnu: pnu },
    { swr: { shouldRetryOnError: false } },
  )
  const { data: permissions } = useGetPermissionsDataV1GovPermissionsGet({
    pnu: pnu,
    archGubuns: '신축,증축,대수선,개축,이전',
    minReferenceDateYm: format(lastYear, 'yyyyMM'),
  })
  const { uid } = useDetailInfo()
  const { codeToName } = useCommonCode()
  const { showLand, openPermitDialog } = useFullDetailDialog()
  const [selected, setSelected] = useState<string>('0')

  if (!ledger?.data) {
    return <SkeletonComponent width={345} height={180} />
  }

  const hasBuilding = ledger.data.length !== 0

  const ledgerSummaryItem = {
    title: t('total_ledger'),
    content: ledgerSummary && <LedgerInfoTable data={ledgerSummary} />,
  }
  const ledgerItems = ledger.data.map((data) => ({
    title: data.dongName ? `${t('ledger')}(${data.dongName})` : t('ledger'),
    content: <LedgerInfoTable key={data.mgmBldrgstPk} data={data} />,
  }))
  const combinedLedgerItems = ledgerSummary ? [ledgerSummaryItem, ...ledgerItems] : [...ledgerItems]

  const tabItems = combinedLedgerItems.map((item, index) => ({
    text: item.title,
    value: index.toString(),
  }))
  const selectedContent = combinedLedgerItems[Number(selected)]?.content
  const hasMultipleTabs = combinedLedgerItems.length > 1

  const handlePermissionClick = (pk: string) => () => {
    openPermitDialog(pk)
  }
  const handleTabChange = (value: string) => {
    setSelected(value)
  }

  const permissionStepInfo = (data: PermissionOut) => {
    switch (data.permissionStep) {
      case '허가/신고': {
        if (data?.delayConstructionDateYmd) {
          return `${formatDateYmd(data.delayConstructionDateYmd)} ${t('delay_construction')}`
        }
        if (data?.scheduledConstructionDateYmd) {
          return `${formatDateYmd(data.scheduledConstructionDateYmd)} ${t('scheduled_construction')}`
        }
        return `${formatDateYmd(data.permissionConstructionDateYmd)} ${t('permission')}`
      }
      case '착공': {
        return `${formatDateYmd(data.realConstructionDateYmd)} ${t('start_construction')}`
      }
      case '사용승인': {
        return `${formatDateYmd(data.useAprDateYmd)} ${t('use_approval')}`
      }
      default: {
        return t('not_exists')
      }
    }
  }

  const ledgerSection = hasMultipleTabs ? (
    <>
      <ScrollableTabButtonBox>
        <TabButton items={tabItems} size="sm" onSelect={handleTabChange} selected={selected} />
      </ScrollableTabButtonBox>
      {selectedContent}
    </>
  ) : (
    <LedgerInfoTable data={ledger.data[0]} />
  )

  return (
    <Box>
      <Separated with={<Box width="100%" height="1px" borderTop="1px solid var(--color-gray-300)" margin="30px 0" />}>
        <Box display="flex" flexDirection="column" gap={4}>
          <Typography variant="subtitle" fontWeight="semibold" color="var(--color-gray-800)">
            {detail('ledger')}
          </Typography>
          {hasBuilding ? ledgerSection : <Nodata title={msg('no_building_information')} />}
          <Button
            key="ledger"
            size="md"
            variant="filled"
            color="gray"
            content={detail('ledger_detail')}
            width="100%"
            disabled={!hasBuilding}
            onClick={() => {
              showLand(uid, 'ledger')
            }}
          />
          <DataSourceUpdate category="land_detail.building.info" />
        </Box>
        <Box display="flex" flexDirection="column" gap={4}>
          <Typography variant="subtitle" fontWeight="semibold" color="var(--color-gray-800)">
            {detail('permission')}
          </Typography>
          <Typography variant="caption1" color="var(--color-gray-800)" whiteSpace="pre-wrap">
            {msg('permit_summary')}
          </Typography>
          <Box display="flex" flexDirection="column" gap="10px">
            {permissions?.data.length ? (
              permissions.data.map((value, idx) => {
                return (
                  <Box
                    key={`${idx}+${value}`}
                    display="flex"
                    flexDirection="column"
                    gap="4px"
                    borderRadius="6px"
                    p="14px"
                    backgroundColor="gray-100"
                    cursor="pointer"
                    onClick={handlePermissionClick(value.mgmPmsrgstPk)}
                  >
                    <Box display="flex" alignItems="center" gap="6px">
                      <Separated with={<Box width="1px" height="14px" borderLeft="1px solid var(--color-gray-500)" />}>
                        {value.mainPurposeIndicationCode && (
                          <Typography fontWeight="semibold" color="var(--color-gray-800)">
                            {codeToName(value.mainPurposeIndicationCode)}
                          </Typography>
                        )}
                        <Typography fontWeight="semibold" color="var(--color-gray-800)">
                          {value.archGubun ?? '-'}
                        </Typography>
                        <Typography fontWeight="semibold" color="var(--color-gray-800)">
                          {value.permissionStep ?? '-'}
                        </Typography>
                        <Typography fontWeight="semibold" color="var(--color-gray-800)">
                          {permissionStepInfo(value)}
                        </Typography>
                      </Separated>
                    </Box>
                    <Typography>{value.buildingName ?? '-'}</Typography>
                  </Box>
                )
              })
            ) : (
              <Nodata title={msg('no_exists_latest_year_permission_data')} />
            )}
          </Box>

          <Button
            key="permission"
            size="md"
            variant="filled"
            color="gray"
            content={detail('permission_detail')}
            width="100%"
            onClick={() => {
              showLand(uid, 'permission')
            }}
          />
          <DataSourceUpdate category="land_detail.building.permission" />
        </Box>
        <Box display="flex" flexDirection="column" gap={4}>
          <Typography variant="subtitle" fontWeight="semibold" color="var(--color-gray-800)">
            {detail('demolish')}
          </Typography>
          <Button
            key="demolish"
            size="md"
            variant="filled"
            color="gray"
            content={detail('demolish_detail')}
            width="100%"
            onClick={() => {
              showLand(uid, 'demolish')
            }}
          />
        </Box>
      </Separated>
    </Box>
  )
}

export default LandDetailTable
