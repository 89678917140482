import { useBooleanState } from '@toss/react'
import { noop } from '@toss/utils'
import { createContext, Dispatch, ReactNode, useContext, useMemo, useReducer } from 'react'
import { useNextRouter } from '~/libs/hooks/useNextRouter'
import { parseQueryToNilNumber, parseQueryToString } from '~/libs/utils/query'

export const CompareState = {
  NEW: 'new',
  SAVED: 'saved',
  EDITING: 'editing',
} as const

export type CompareStateType = (typeof CompareState)[keyof typeof CompareState]

interface CompareData {
  state: CompareStateType
  comparisonId?: number
  comparisonName?: string
}

type NewAction = {
  type: 'NEW'
}
type ToSavedAction = {
  type: 'TO_SAVED'
  comparisonName: string
  comparisonId: number
}
type EditAction = {
  type: 'EDIT'
}

type CompareStateActionTypes = NewAction | ToSavedAction | EditAction

interface CompareHeaderContextType {
  compareData: CompareData
  dispatch: Dispatch<CompareStateActionTypes>
  propertyTypeCode: string
  realEstateType: string
  isShowNotice: boolean
  showNotice: () => void
  hideNotice: () => void
}

const CompareHeaderContext = createContext<CompareHeaderContextType>({
  compareData: { state: CompareState.NEW },
  dispatch: () => null,
  propertyTypeCode: '',
  realEstateType: '',
  isShowNotice: false,
  showNotice: noop,
  hideNotice: noop,
})

interface CompareHeaderProviderProps {
  children: ReactNode
}

const reducer = (state: CompareData, action: CompareStateActionTypes): CompareData => {
  switch (action.type) {
    case 'NEW':
      return { state: CompareState.NEW, comparisonName: undefined }
    case 'TO_SAVED':
      return {
        ...state,
        state: CompareState.SAVED,
        comparisonName: action.comparisonName,
        comparisonId: action.comparisonId,
      }
    case 'EDIT':
      return { ...state, state: CompareState.EDITING }
    default:
      return state
  }
}

const CompareStateProvider = ({ children }: CompareHeaderProviderProps) => {
  const router = useNextRouter()
  const { comparisonId, comparisonName, realEstateType, comparePropertyType } = router.query

  let initialValue: CompareData = { state: CompareState.NEW }

  if (comparisonId !== undefined && comparisonName !== undefined) {
    initialValue = {
      ...initialValue,
      state: CompareState.SAVED,
      comparisonName: parseQueryToString(comparisonName),
      comparisonId: parseQueryToNilNumber(comparisonId),
    }
  }

  const [compareData, dispatch] = useReducer(reducer, initialValue)
  const [isShowNotice, showNotice, hideNotice] = useBooleanState(false)

  const value = useMemo(
    () => ({
      compareData,
      dispatch,
      propertyTypeCode: parseQueryToString(comparePropertyType),
      realEstateType: parseQueryToString(realEstateType),
      isShowNotice,
      showNotice,
      hideNotice,
    }),
    [compareData, isShowNotice],
  )

  return <CompareHeaderContext.Provider value={value}>{children}</CompareHeaderContext.Provider>
}

const useCompareStateContext = () => {
  const { compareData, dispatch, propertyTypeCode, realEstateType, isShowNotice, hideNotice, showNotice } =
    useContext(CompareHeaderContext)
  const { state, comparisonName, comparisonId } = compareData
  return {
    state,
    comparisonName,
    comparisonId,
    dispatch,
    propertyTypeCode,
    realEstateType,
    isShowNotice,
    showNotice,
    hideNotice,
  }
}

export { useCompareStateContext }

export default CompareStateProvider
