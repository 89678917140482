import { formatCommaizeRoundAndFixToNDecimal, formatOnes, formatRoundToNearestPlace } from '~/libs/utils/format'
import { useTranslation } from 'next-i18next'
import { decommaizeNumber, isNotNil } from '@toss/utils'
import {
  divideByHundredMillion,
  divideByMillion,
  divideByTenThousand,
  divideByThousand,
  HUNDRED,
  HUNDRED_MILLION,
  MILLION,
  TEN_THOUSAND,
  THOUSAND,
  toPyNumber,
} from '~/libs/utils/number'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import { lowerCase } from 'lower-case'
import useUsdExchange from '~/libs/hooks/useUsdExchange'

const NumberFormatter: Record<NumberFormatType, (number: number) => number> = {
  won: (number) => number,
  man: (number) => divideByTenThousand(number)!,
  eok: (number) => divideByHundredMillion(number)!,
  dollar: (number) => number,
  thousand_dollar: (number) => divideByThousand(number)!,
  million_dollar: (number) => divideByMillion(number)!,
}

const krwToUsdNumberFormat: Record<string, NumberFormatType> = {
  won: 'dollar',
  man: 'dollar',
  eok: 'million_dollar',
}

const numberUnitFormatForTranslation: Record<NumberFormatType, string> = {
  won: 'won',
  man: 'man',
  eok: 'eok',
  dollar: 'dollar',
  thousand_dollar: 'cheon',
  million_dollar: 'million',
}

const DecimalRoundedFormatter: Record<DecimalRoundedFormatType, (number: number) => string> = {
  n: formatOnes,
  n1: (number: number) => formatCommaizeRoundAndFixToNDecimal(number, 1)!,
  n2: (number: number) => formatCommaizeRoundAndFixToNDecimal(number, 2)!,
}

type FormatOptionType = {
  format?: NumberFormatType
  decimalRoundUnit?: DecimalRoundedFormatType
  roundUnit?: number
  areaUnit?: AreaUnit
  currencyUnit?: CurrencyUnit
  isOnlyNumber?: boolean
  skipZero?: boolean
}

export type FormatterType = (number: NullishNumber, options?: FormatOptionType) => string

export const useCurrencyFormat = (): FormatterType => {
  const { currencyUnit } = useUserConfiguration()
  const { ammount } = useUsdExchange()
  const { t: currencyTerm } = useTranslation('common', { keyPrefix: 'currency' })

  return (
    number,
    options = { format: 'won', decimalRoundUnit: 'n', currencyUnit: 'KRW', isOnlyNumber: false, skipZero: false },
  ) => {
    if (options.skipZero && number === 0) {
      return '-'
    }

    if (!isNotNil(number)) {
      return '-'
    }

    options.format ??= 'won'
    options.decimalRoundUnit ??= 'n'
    options.currencyUnit ??= currencyUnit

    const isKrw = options.currencyUnit === 'KRW'

    const numberTransformed = isKrw ? number : number / ammount
    options.format = isKrw ? options.format : krwToUsdNumberFormat[options.format]
    const numberFormatted = NumberFormatter[options.format]?.(
      options.roundUnit ? formatRoundToNearestPlace(numberTransformed, options.roundUnit)! : numberTransformed,
    )
    const numberRounded = DecimalRoundedFormatter[options.decimalRoundUnit]?.(numberFormatted)

    if (options.isOnlyNumber) {
      return numberRounded
    }

    return currencyTerm(options.format, {
      number: numberRounded,
    })
  }
}

const truncateAbove100 = (number: number) => {
  return number >= HUNDRED ? DecimalRoundedFormatter['n']?.(number) : number
}

const getFormatKeys = (number: number): { roundedKey: DecimalRoundedFormatType; unitKey: NumberFormatType } => {
  if (number >= HUNDRED_MILLION) {
    return { roundedKey: 'n1', unitKey: 'eok' }
  }
  if (number >= TEN_THOUSAND) {
    return { roundedKey: 'n1', unitKey: 'man' }
  }
  return { roundedKey: 'n', unitKey: 'won' }
}

export const useAbbreviatedCurrencyUnitFormat = () => {
  const { currencyUnit, areaUnit } = useUserConfiguration()
  const { ammount } = useUsdExchange()
  const { t } = useTranslation('common', { keyPrefix: 'currency' })
  const { t: common } = useTranslation('common', { keyPrefix: 'common_term' })

  const isKrw = currencyUnit === 'KRW'

  const convertNumberByExchangeRate = (number: number) => {
    return isKrw ? number : number / ammount
  }

  const convertNumberByAreaUnit = (number: number) => (areaUnit === 'PY' ? toPyNumber(number, 'per_unit') : number)

  const getUsdFormatKeys = (number: number): { roundedKey: DecimalRoundedFormatType; unitKey: NumberFormatType } => {
    const convertedNumber = convertNumberByExchangeRate(number)
    if (convertedNumber >= MILLION) {
      return { roundedKey: 'n1', unitKey: 'million_dollar' }
    }
    if (convertedNumber >= THOUSAND) {
      return { roundedKey: 'n1', unitKey: 'thousand_dollar' }
    }
    return { roundedKey: 'n', unitKey: 'dollar' }
  }

  const getCurrencyFormatKeys = (number: number) => {
    return isKrw ? getFormatKeys(number) : getUsdFormatKeys(number)
  }

  const formatNumberByUnit = (number: number) => {
    return NumberFormatter[getCurrencyFormatKeys(number).unitKey]?.(convertNumberByExchangeRate(number))
  }

  const abbreviateNumberByUnit = (
    number: NullishNumber,
    decimalRoundedKey: DecimalRoundedFormatType,
    roundUnit?: number,
  ) => {
    if (!isNotNil(number)) {
      return
    }

    const numberRounded = DecimalRoundedFormatter[decimalRoundedKey](
      formatNumberByUnit(roundUnit ? formatRoundToNearestPlace(number, roundUnit)! : number),
    )
    return truncateAbove100(decommaizeNumber(numberRounded))
  }

  const getAbbreviatedCurrencyValue = (number: NullishNumber, isPerUnit?: boolean, roundUnit?: number) => {
    if (!isNotNil(number)) {
      return
    }
    const convertedNumber = isPerUnit ? convertNumberByAreaUnit(number) : number

    return abbreviateNumberByUnit(convertedNumber, getCurrencyFormatKeys(convertedNumber).roundedKey, roundUnit)
  }

  const getCurrencyUnit = (number: NullishNumber, isPerUnit?: boolean, roundUnit?: number) => {
    if (!isNotNil(number)) {
      return
    }
    const convertedNumber = isPerUnit ? convertNumberByAreaUnit(number) : number
    const unitKey = getCurrencyFormatKeys(
      roundUnit ? formatRoundToNearestPlace(convertedNumber, roundUnit)! : convertedNumber,
    ).unitKey
    return unitKey === 'dollar' || unitKey === 'won' ? undefined : common(numberUnitFormatForTranslation[unitKey])
  }

  const getAbbreviatedCurrencyUnit = (number: NullishNumber, isPerUnit?: boolean) => {
    if (!isNotNil(number)) {
      return
    }
    const convertedNumber = isPerUnit ? convertNumberByAreaUnit(number) : number
    const formattedNumber = abbreviateNumberByUnit(convertedNumber, getCurrencyFormatKeys(convertedNumber).roundedKey)

    return t(getCurrencyFormatKeys(convertedNumber).unitKey, { number: formattedNumber })
  }

  return { getAbbreviatedCurrencyValue, getCurrencyUnit, getAbbreviatedCurrencyUnit }
}

export const useAreaFormat = (): FormatterType => {
  const { areaUnit } = useUserConfiguration()
  const { t } = useTranslation('common', {
    keyPrefix: 'area',
  })

  return (
    number,
    options = { format: 'won', decimalRoundUnit: 'n', areaUnit: areaUnit, isOnlyNumber: false, skipZero: false },
  ) => {
    if (options.skipZero && number === 0) {
      return '-'
    }

    if (!isNotNil(number)) {
      return '-'
    }

    options.format ??= 'won'
    options.decimalRoundUnit ??= 'n'
    options.areaUnit ??= areaUnit

    const numberTransformed = options.areaUnit === 'PY' ? toPyNumber(number, 'total') : number
    const numberFormatted = NumberFormatter[options.format]?.(
      options.roundUnit ? formatRoundToNearestPlace(numberTransformed, options.roundUnit)! : numberTransformed,
    )
    const numberRounded = DecimalRoundedFormatter[options.decimalRoundUnit]?.(numberFormatted)

    if (options.isOnlyNumber) {
      return numberRounded
    }

    return t(`${lowerCase(options.areaUnit)}.${options.format}`, {
      number: numberRounded,
    })
  }
}

export const useAbbreviatedAreaUnitFormat = () => {
  const { t } = useTranslation('common', { keyPrefix: 'area' })
  const { areaUnit } = useUserConfiguration()

  const formatNumberByUnit = (number: number) => {
    const numberTransformed = areaUnit === 'PY' ? toPyNumber(number, 'total') : number
    return NumberFormatter['won']?.(numberTransformed)
  }

  const abbreviateNumberByUnit = (
    number: NullishNumber,
    roundedKey: DecimalRoundedFormatType,
    unitKey: NumberFormatType,
  ) => {
    if (!isNotNil(number)) {
      return '-'
    }
    const numberRounded = DecimalRoundedFormatter[roundedKey](NumberFormatter[unitKey]?.(formatNumberByUnit(number)))
    return truncateAbove100(decommaizeNumber(numberRounded))
  }

  const getAbbreviatedAreaValue = (number: NullishNumber) => {
    if (!isNotNil(number)) {
      return
    }
    const { roundedKey, unitKey } = getFormatKeys(formatNumberByUnit(number))
    return abbreviateNumberByUnit(number, roundedKey, unitKey)
  }

  const getAreaUnit = (number: NullishNumber) => {
    if (!isNotNil(number)) {
      return
    }
    const { unitKey } = getFormatKeys(formatNumberByUnit(number))
    return t(`${lowerCase(areaUnit)}.${unitKey}`, { number: undefined })
  }

  const getAbbreviatedAreaUnit = (number: NullishNumber) => {
    if (!isNotNil(number)) {
      return '-'
    }
    const { roundedKey, unitKey } = getFormatKeys(formatNumberByUnit(number))
    const formattedNumber = abbreviateNumberByUnit(number, roundedKey, unitKey)
    return t(`${lowerCase(areaUnit)}.${unitKey}`, { number: formattedNumber })
  }

  return { getAbbreviatedAreaValue, getAreaUnit, getAbbreviatedAreaUnit }
}

export const usePerUnitFormat = (): FormatterType => {
  const { areaUnit, currencyUnit } = useUserConfiguration()
  const { ammount } = useUsdExchange()
  const { t } = useTranslation('common', { keyPrefix: 'per_unit' })

  return (
    number,
    options = {
      format: 'won',
      decimalRoundUnit: 'n',
      areaUnit: areaUnit,
      currencyUnit: currencyUnit,
      isOnlyNumber: false,
      skipZero: false,
    },
  ) => {
    if (options.skipZero && number === 0) {
      return '-'
    }

    if (!isNotNil(number)) {
      return '-'
    }

    options.format ??= 'won'
    options.decimalRoundUnit ??= 'n'
    options.areaUnit ??= areaUnit
    options.currencyUnit ??= currencyUnit

    const isKrw = options.currencyUnit === 'KRW'
    options.format = isKrw ? options.format : krwToUsdNumberFormat[options.format]

    const numberTransformed = options.areaUnit === 'PY' ? toPyNumber(number, 'per_unit') : number
    const numberTransformedWithCurrency = isKrw ? numberTransformed : numberTransformed / ammount
    const numberFormatted = NumberFormatter[options.format]?.(
      options.roundUnit
        ? formatRoundToNearestPlace(numberTransformedWithCurrency, options.roundUnit)!
        : numberTransformedWithCurrency,
    )
    const numberRounded = DecimalRoundedFormatter[options.decimalRoundUnit]?.(numberFormatted)

    if (options.isOnlyNumber) {
      return numberRounded
    }

    return t(`${lowerCase(options.areaUnit)}.${options.format}`, {
      number: numberRounded,
    })
  }
}

export const usePerUnitValueAccessor = (areaUnit: AreaUnit, currencyUnit: CurrencyUnit) => {
  const perUnitFormat = usePerUnitFormat()

  return (options?: FormatOptionType) => {
    const perUnitValueAccessor: Record<string, (field: string, object: any) => string> = {
      PY_KRW: (field: string, object: any) =>
        perUnitFormat(object[field], { ...options, areaUnit: 'PY', currencyUnit: 'KRW' }),
      M2_KRW: (field: string, object: any) =>
        perUnitFormat(object[field], { ...options, areaUnit: 'M2', currencyUnit: 'KRW' }),
      PY_USD: (field: string, object: any) =>
        perUnitFormat(object[field], { ...options, areaUnit: 'PY', currencyUnit: 'USD' }),
      M2_USD: (field: string, object: any) =>
        perUnitFormat(object[field], { ...options, areaUnit: 'M2', currencyUnit: 'USD' }),
    }

    return perUnitValueAccessor[`${areaUnit}_${currencyUnit}`]
  }
}

export const useAreaValueAccessor = (areaUnit: AreaUnit) => {
  const areaFormat = useAreaFormat()

  return (options?: FormatOptionType) =>
    areaUnit === 'PY'
      ? (field: string, object: any) => areaFormat(object[field], { ...options, areaUnit: 'PY' })
      : (field: string, object: any) => areaFormat(object[field], { ...options, areaUnit: 'M2' })
}

export const useFormattedRentAreaValue = (areaUnit: AreaUnit) => {
  const areaFormat = useAreaFormat()

  return (value: number | null, leasePartYn: boolean, options?: FormatOptionType) => {
    const formattedValue = areaFormat(value ?? (leasePartYn ? 0 : value), {
      ...options,
      areaUnit: areaUnit === 'PY' ? 'PY' : 'M2',
      skipZero: !leasePartYn,
    })

    const leasePartSuffix = leasePartYn ? '+' : ''
    return `${formattedValue}${leasePartSuffix}`
  }
}

export const useRentAreaValueAccessor = (areaUnit: AreaUnit) => {
  const formatRentAreaValue = useFormattedRentAreaValue(areaUnit)

  return (options?: FormatOptionType) => (field: string, object: any) => {
    const value = object[field]
    const leasePartYn = Boolean(object['leasePartYn'])

    return formatRentAreaValue(value, leasePartYn, options)
  }
}

export const useCurrencyValueAccessor = (currencyUnit: CurrencyUnit) => {
  const currencyFormatter = useCurrencyFormat()

  return (options?: FormatOptionType) =>
    currencyUnit === 'KRW'
      ? (field: string, object: any) => currencyFormatter(object[field], { ...options, currencyUnit: 'KRW' })
      : (field: string, object: any) => currencyFormatter(object[field], { ...options, currencyUnit: 'USD' })
}

export const useAreaUnitLabel = () => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { areaUnit } = useUserConfiguration()

  return areaUnit === 'PY' ? t('py') : t('m2')
}
