/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.7.0
 */

export type LandOrderByGroup = typeof LandOrderByGroup[keyof typeof LandOrderByGroup];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LandOrderByGroup = {
  realTradeDateDesc: 'realTradeDateDesc',
  realTradePriceDesc: 'realTradePriceDesc',
  realTradePriceAsc: 'realTradePriceAsc',
  realTradePricePerTotalNlaAreaDesc: 'realTradePricePerTotalNlaAreaDesc',
  realTradePricePerTotalNlaAreaAsc: 'realTradePricePerTotalNlaAreaAsc',
  totalAreaDesc: 'totalAreaDesc',
  totalAreaAsc: 'totalAreaAsc',
  constructDateDesc: 'constructDateDesc',
  constructDateAsc: 'constructDateAsc',
  landAreaDesc: 'landAreaDesc',
  landAreaAsc: 'landAreaAsc',
} as const;
