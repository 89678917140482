import { LwhLeaseTrendOut, LwhLeaseTrendOutValue } from '~/libs/apis/data/model'
import { SeriesModel } from '@syncfusion/ej2-charts'
import { useGetLwhLeaseTrendDataV1BuildingsLwhLeaseTrendGet } from '~/libs/apis/data/buildings-lwh/buildings-lwh'
import { LwhLeaseCompareType } from '~/templates/compare/lwh/content/chart/CompareLwhLineChart'
import { AxisModel } from '@syncfusion/ej2-charts/src/chart/axis/axis-model'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import { useTranslation } from 'next-i18next'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import { isNotNil, roundToUnit } from '@toss/utils'
import { formatDateYq, parseDateYq } from '~/libs/utils/date'
import { getChartColorKey, LegendType } from '~/templates/compare/office/content/chart/useOfficeCompareChart'
import { useTheme } from '@xstyled/styled-components'
import { commonCircleMarkerSettings, commonEmptyPointSettings } from '~/libs/constants/chart'
import useCompareGrid from '~/templates/compare/common/hooks/useCompareGrid'
import useGetLabelFormat from '~/libs/hooks/useGetLabelFormat'
import useUnitTransformer from '~/libs/hooks/useUnitTransformer'
import { useCompareListContext } from '~/templates/compare/common/CompareListProvider'
import { LwhItem } from '~/templates/compare/lwh/CompareLwh'
import useLastUpdateDate from '~/libs/hooks/useLastUpdateDate'
import { startOfQuarter } from 'date-fns'
import { LWH_LEASE_MINIMUM_YEAR_QUARTER } from '~/templates/common/types/MarketRentMarketByBoeTypes'
import { getDateByYearAndQuarter } from '~/libs/utils/chart'

function useLwhCompareChart(compareType: LwhLeaseCompareType) {
  const theme = useTheme()
  const { t: chart } = useTranslation('chart', { keyPrefix: 'label_format' })
  const { list: lwhs } = useCompareListContext<LwhItem>()
  const raIds = lwhs.map((item) => item.raId).join(',')
  const { areaUnit, currencyUnit } = useUserConfiguration()
  const labelFormat = useGetLabelFormat(areaUnit, currencyUnit)
  const { unitConverterForClient } = useUnitTransformer()
  const { columns, headerColumn, getGridDataSource } = useCompareGrid(compareType, lwhs)
  const { data } = useGetLwhLeaseTrendDataV1BuildingsLwhLeaseTrendGet(
    {
      raIds,
      valueType: compareType,
    },
    { swr: { keepPreviousData: true } },
  )
  const { lastUpdate } = useLastUpdateDate()

  const isEmptyRate = compareType === 'roomEmptyRate' || compareType === 'lowEmptyRate'

  const legend: LegendType[] = lwhs.map((option, index) => ({
    label: option.buildingName,
    color: theme.colors[getChartColorKey(index)].toString(),
    dataKey: option.raId,
  }))

  const getDataValue = (value: LwhLeaseTrendOutValue) => {
    if (isNotNil(value)) {
      if (isEmptyRate) {
        return roundToUnit(value, 0.01)
      }
      return roundToUnit(unitConverterForClient(value, 'per_unit'), 1)
    }
    return undefined
  }

  const getDataSource = (data: LwhLeaseTrendOut[] | undefined) => {
    const chartData = data?.map?.((item) => ({
      dateYq: parseDateYq(item.dateYq),
      raId: item.raId,
      value: getDataValue(item.value),
      yearQuarter: formatDateYq(item.dateYq),
    }))
    return Object.groupBy(chartData ?? [], ({ raId }) => raId)
  }

  const series: SeriesModel[] = legend.map((legendItem) => ({
    xName: 'dateYq',
    yName: 'value',
    fill: legendItem.color,
    dataSource: getDataSource(data)[legendItem.dataKey],
    name: legendItem.label,
    tooltipMappingName: 'yearQuarter',
    marker: commonCircleMarkerSettings,
    emptyPointSettings: commonEmptyPointSettings,
  }))

  const primaryXAxis: AxisModel = {
    interval: 3,
    intervalType: 'Months',
    valueType: 'DateTime',
    minimum: startOfQuarter(
      getDateByYearAndQuarter(LWH_LEASE_MINIMUM_YEAR_QUARTER.year, LWH_LEASE_MINIMUM_YEAR_QUARTER.quarter),
    ),
    maximum: startOfQuarter(getDateByYearAndQuarter(lastUpdate.year, lastUpdate.quarter)),
  }

  const primaryYAxis: AxisModel = {
    labelFormat: isEmptyRate ? chart('percent') : labelFormat,
  }

  const column: ColumnModel[] = [headerColumn, ...columns]

  const dataSource = getGridDataSource(data)
  return { data, series, primaryXAxis, primaryYAxis, column, dataSource }
}

export default useLwhCompareChart
