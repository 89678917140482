import React, { FC, useState } from 'react'
import { useTranslation } from 'next-i18next'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import { SwitchCase } from '@toss/react'
import TabButton from '~/components/TabButton/TabButton'
import OfficeLesseeInfo from '~/templates/place/detail/lessee/OfficeLesseeInfo'
import Sbiz from '~/templates/place/detail/lessee/Sbiz'
import LwhLesseeInfo from '~/templates/place/detail/lessee/LwhLesseeInfo'
import NaverGrid from '~/templates/place/detail/lessee/NaverGrid'
import KakaoGrid from '~/templates/place/detail/lessee/KakaoGrid'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import Button from '~/components/Button/Button'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'
import ScrollableTabButtonBox from '~/components/TabButton/ScrollableTabButtonBox'
import NoDataWithInquiry from '~/templates/place/detail/NoDataWithInquiry'
import useExistLessee from '~/templates/place/detail/lessee/useExistLessee'
import useLastUpdateDate from '~/libs/hooks/useLastUpdateDate'
import { useOfficeLesseeDataV1BuildingsOfficeLesseeGet } from '~/libs/apis/data/buildings-office/buildings-office'
import { useLwhLesseeDataV1BuildingsLwhLesseeGet } from '~/libs/apis/data/buildings-lwh/buildings-lwh'
import TenantDataSource from '~/templates/common/dataSource/TenantDataSource'
import DataSourceUpdate from '~/components/DataSource/DataSourceUpdate'

const PAGE_SIZE = 5

interface LesseeInfoProps {
  pnu: string | null
  type?: 'office' | 'lwh' | 'land'
}

type LesseeType = 'info' | 'sbiz' | 'naver' | 'kakao'

const LesseeInfo: FC<LesseeInfoProps> = ({ pnu, type = 'office' }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: msg } = useTranslation('common', { keyPrefix: 'common_msg' })
  const isLand = type === 'land'
  const [selected, setSelected] = useState<LesseeType>(isLand ? 'sbiz' : 'info')
  const [pageNum, setPageNum] = useState(1)
  const buildingTabItems = [
    { text: t('rsquare_research'), value: 'info' },
    { text: t('sbiz'), value: 'sbiz' },
    { text: t('naver_map'), value: 'naver' },
    { text: t('kakao_map'), value: 'kakao' },
  ]
  const landTabItems = [
    { text: t('sbiz'), value: 'sbiz' },
    { text: t('naver_map'), value: 'naver' },
    { text: t('kakao_map'), value: 'kakao' },
  ]
  const tabItems = isLand ? landTabItems : buildingTabItems
  const { uid, detailType } = useDetailInfo()
  const { showOffice, showLwh } = useFullDetailDialog()
  const { existAll, existQuarter } = useExistLessee()
  const { lastUpdateParams } = useLastUpdateDate('quarter')

  const { data: officeLesseeInfo, isLoading: isOfficeLesseeInfoLoading } =
    useOfficeLesseeDataV1BuildingsOfficeLesseeGet(
      { raId: uid, pageNum, pageSize: PAGE_SIZE, year: lastUpdateParams.year, quarter: lastUpdateParams.quarter! },
      {
        swr: { keepPreviousData: true, enabled: existQuarter && type === 'office' },
      },
    )
  const { data: lwhLesseeInfo, isLoading: isLwhLesseInfoLoading } = useLwhLesseeDataV1BuildingsLwhLesseeGet(
    { raId: uid, pageNum, pageSize: PAGE_SIZE, year: lastUpdateParams.year, quarter: lastUpdateParams.quarter! },
    {
      swr: { keepPreviousData: true, enabled: existQuarter && type === 'lwh' },
    },
  )
  const lesseeInfoData = type === 'office' ? officeLesseeInfo?.data : lwhLesseeInfo?.data

  const handlePageChange = (page: number) => setPageNum(page)

  const handleSelected = (value: string) => {
    setSelected(value as LesseeType)
  }

  const handleStackingPlanClick = () => {
    if (type === 'office') {
      showOffice(uid, 'stackingPlan')
    } else {
      showLwh(uid, 'stackingPlan')
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap="12px">
      <Box display="flex" gap={2.5}>
        <Typography fontWeight="semibold" variant="subtitle">
          {t('lessee')}
        </Typography>
        {!isLand && detailType === 'panel' && existAll && (
          <Button
            content={t('show_stacking_plan')}
            size="sm"
            color="gray"
            variant="line"
            onClick={handleStackingPlanClick}
          />
        )}
      </Box>
      {pnu && (
        <ScrollableTabButtonBox>
          <TabButton items={tabItems} size="sm" onSelect={handleSelected} selected={selected} />
        </ScrollableTabButtonBox>
      )}
      <SwitchCase
        caseBy={{
          info: (
            <>
              {existQuarter ? (
                <SwitchCase
                  caseBy={{
                    office: (
                      <OfficeLesseeInfo
                        data={officeLesseeInfo}
                        isLoading={isOfficeLesseeInfoLoading}
                        onPageChange={handlePageChange}
                      />
                    ),
                    lwh: (
                      <LwhLesseeInfo
                        data={lwhLesseeInfo}
                        isLoading={isLwhLesseInfoLoading}
                        onPageChange={handlePageChange}
                      />
                    ),
                  }}
                  value={type}
                />
              ) : (
                <NoDataWithInquiry type="lessee" description={msg('no_rsquare_lease_survey_data')} />
              )}
              <TenantDataSource data={lesseeInfoData} />
            </>
          ),
          sbiz: pnu ? (
            <>
              <Sbiz pnu={pnu} />
              <DataSourceUpdate category="detail.lessee.sbiz" />
            </>
          ) : null,
          naver: pnu ? (
            <>
              <NaverGrid pnu={pnu} />
              <DataSourceUpdate category="detail.lessee.naver" />
            </>
          ) : null,
          kakao: pnu ? (
            <>
              <KakaoGrid pnu={pnu} />
              <DataSourceUpdate category="detail.lessee.kakao" />
            </>
          ) : null,
        }}
        value={selected}
      />
    </Box>
  )
}

export default LesseeInfo
