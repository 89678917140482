import { FC, useState } from 'react'
import useCommonCode from '~/libs/hooks/useCommonCode'
import { useAreaFormat } from '~/libs/hooks/useFormat'
import type { BuildingsOut } from '~/libs/apis/data/model'
import { isNotNil } from '@toss/utils'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import { useTranslation } from 'react-i18next'
import Dialog from '~/components/Dialog/Dialog'
import Button from '~/components/Button/Button'

interface BuildingInformationDialogProps {
  isOpen: boolean
  address: string
  buildings: BuildingsOut[]
  onClose: () => void
  onSelect: (item: BuildingsOut) => void
}
const BuildingInformationDialog: FC<BuildingInformationDialogProps> = ({
  isOpen,
  address,
  buildings,
  onClose,
  onSelect,
}) => {
  const { t } = useTranslation('place', { keyPrefix: 'building_information' })
  const { t: commonTerm } = useTranslation('common', { keyPrefix: 'common_term' })

  const { codeToName } = useCommonCode()
  const areaFormatter = useAreaFormat()

  const [selected, setSelected] = useState<BuildingsOut | null>(null)

  if (!isOpen && selected) {
    setSelected(null)
  }

  const formatBuildingInfo = (item: BuildingsOut) => {
    const list: string[] = [codeToName(item.propertyTypeCode) ?? '', codeToName(item.constructStatusCode) ?? '']

    if (isNotNil(item.totalArea)) {
      list.push(areaFormatter(item.totalArea, { decimalRoundUnit: 'n2' }))
    }

    if (isNotNil(item.constructDateY)) {
      const constructDate = item.constructDateY
      const remodelingDate = isNotNil(item.remodelingDateY) ? `(${item.remodelingDateY})` : ''
      list.push(`${constructDate}${remodelingDate}`)
    }

    return list.join(' · ')
  }

  const handleSelectButtonClick = () => {
    if (!isNotNil(selected)) {
      return
    }
    onClose()
    onSelect(selected)
  }

  const footerTemplate = () => (
    <Box display="flex" gap={2} justifyContent="center">
      <Button content={commonTerm('cancel')} variant="line" color="gray" width="120px" size="lg" onClick={onClose} />
      <Button
        content={t('select_complete')}
        width="120px"
        size="lg"
        disabled={!isNotNil(selected)}
        onClick={handleSelectButtonClick}
      />
    </Box>
  )

  return (
    <Dialog target="#dialog-target" width="460px" visible={isOpen} title={t('title')} footerTemplate={footerTemplate}>
      <Box width="100%" display="flex" flexDirection="column" gap="20px" padding="20px">
        <Box flex={1} display="flex" flexDirection="column" gap={2} alignItems="center">
          <Typography fontWeight={600} variant="subtitle">
            {address}
          </Typography>
          <Typography>{t('select_building_to_search_address')}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap="10px">
          {buildings.map((item) => (
            <Box
              key={`building-information-${item.raId}`}
              onClick={() => setSelected(item)}
              backgroundColor={{ _: selected?.raId === item.raId ? 'purple-300' : 'gray-100', hover: 'purple-300' }}
              borderRadius="8px"
              padding="14px 16px"
              display="flex"
              flexDirection="column"
              cursor="pointer"
              color={{
                // @ts-ignore: xstyled color props만 선택자를 통한 스타일지정이 허용되지 않아 에러 발생.
                // 가이드에 따르면 해당 선택자를 rule로 추가해야 하지만, 스타일지정은 되고 있기 때문에 ts-ignore 처리
                hover: { p: 'purple-700' },
              }}
              gap={2}
            >
              <Typography color={selected?.raId === item.raId ? 'purple-700' : 'unset'} fontWeight="600">
                {item.buildingName}
              </Typography>
              <Typography color={selected?.raId === item.raId ? 'purple-700' : 'unset'}>
                {formatBuildingInfo(item)}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Dialog>
  )
}

export default BuildingInformationDialog
