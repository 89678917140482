import { ColumnModel } from '@syncfusion/ej2-react-grids'
import { BeforeOpenEventArgs, DialogComponent } from '@syncfusion/ej2-react-popups'
import { EmitType } from '@syncfusion/ej2/base'
import { Separated, useCombinedRefs } from '@toss/react'
import { forwardRef, RefObject, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import IconConstructBuilding from '~/assets/images/construct-building.svg'
import IconInformation from '~/assets/images/icons/information.svg'
import IconClose from '~/assets/images/icons/x-graphic.svg'
import { Box } from '~/components'
import ScrollBox from '~/components/Box/ScrollBox'
import Button from '~/components/Button/Button'
import DataGrid from '~/components/DataGrid/DataGrid'
import GuideDialog from '~/components/Dialog/GuideDialog'
import ScrollableTabButtonBox from '~/components/TabButton/ScrollableTabButtonBox'
import TabButton from '~/components/TabButton/TabButton'
import DetailTable from '~/components/Table/DetailTable'
import Typography from '~/components/Typography/Typography'
import {
  useGetPermissionOverviewDataV1GovPermissionsMgmPmsrgstPkGet,
  useGetPermissionOverviewDongDataV1GovPermissionsDongsMgmDongOulnPkGet,
  useGetPermissionOverviewDongsLabelsDataV1GovPermissionsMgmPmsrgstPkDongsGet,
  useGetPermissionOverviewFloorsDataV1GovPermissionsDongsMgmDongOulnPkFloorsGet,
} from '~/libs/apis/data/gov/gov'
import { useAreaFormat } from '~/libs/hooks/useFormat'
import { formatDateYmd } from '~/libs/utils/date'
import { formatTotalUnitsInfo, formatVacancyRate } from '~/libs/utils/format'
import { FilterDivider } from '~/templates/common/dialog/FilterDialog'
import GuideContentDot from '~/templates/common/popover/GuideContentDot'
import DetailFooter from '~/templates/DetailFooter'
import StickyBox from '~/templates/StickyBox'
import useNilableValueAccessor from '~/components/DataGrid/hooks/useNilableValueAccessor'
import DataSourceUpdate from '~/components/DataSource/DataSourceUpdate'
import LandInformationButton from '~/templates/common/detail/LandInformationButton'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'
import { DETAIL_FOOTER_BUTTONS_Z_INDEX } from '~/libs/constants/place'
import { isNonEmptyArray } from '@toss/utils'
import Indicator from '~/components/Indicator/Indicator'

interface PermitDetailDialogProps {
  mgmPmsrgstPk: RefObject<string>
  pnu: RefObject<string>
}

const SUB_PARAGRAPH_INDEX = 1

const PermitDetailDialog = forwardRef<DialogComponent, PermitDetailDialogProps>(({ mgmPmsrgstPk, pnu }, parentRef) => {
  const { t: permit } = useTranslation('place', { keyPrefix: 'permit_guide' })
  const { t: common } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: unit } = useTranslation('common', { keyPrefix: 'unit' })
  const { closePermitDialog } = useFullDetailDialog()

  const [dialogVisible, setDialogVisible] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [selectedDongPk, setSelectedDongPk] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)

  const areaFormatter = useAreaFormat()
  const dialogRef = useRef<DialogComponent>(null)
  const ref = useCombinedRefs(dialogRef, parentRef)
  const permitProcessContents = permit('process_step_notice.content', { returnObjects: true })
  const isExistPk = mgmPmsrgstPk.current !== null
  const isSelectedDongPk = selectedDongPk !== ''

  const { data, isLoading } = useGetPermissionOverviewDataV1GovPermissionsMgmPmsrgstPkGet(mgmPmsrgstPk.current!, {
    swr: { enabled: isExistPk },
  })
  const { data: dongList } = useGetPermissionOverviewDongsLabelsDataV1GovPermissionsMgmPmsrgstPkDongsGet(
    mgmPmsrgstPk.current!,
    {
      swr: {
        enabled: isExistPk,
        shouldRetryOnError: false,
        onSuccess: (data) => {
          if (data) {
            setSelectedDongPk(data[0].mgmDongOulnPk)
          }
        },
      },
    },
  )
  const { data: dongData } = useGetPermissionOverviewDongDataV1GovPermissionsDongsMgmDongOulnPkGet(selectedDongPk, {
    swr: {
      enabled: isSelectedDongPk,
    },
  })

  const { data: floorData } = useGetPermissionOverviewFloorsDataV1GovPermissionsDongsMgmDongOulnPkFloorsGet(
    selectedDongPk,
    { pageNum: currentPage, pageSize: 10 },
    { swr: { enabled: isSelectedDongPk } },
  )

  const hasDongList = Array.isArray(dongList) && isNonEmptyArray(dongList)

  const handleOpen = () => {
    setDialogVisible(true)
  }

  const handleClose = () => {
    closePermitDialog()
    setDialogVisible(false)
  }
  const handleOnClose = () => {
    setSelectedDongPk('')
    setCurrentPage(1)
    dialogRef.current?.hide()
  }

  const handleOnSelectTab = (value: string) => {
    setSelectedDongPk(value)
  }

  const handlePageChange = (object: { currentPage: number }) => setCurrentPage(object.currentPage)

  const beforeOpen: EmitType<BeforeOpenEventArgs> = (args) => {
    args.maxHeight = '100vh'
  }

  const basicInfoData = [
    { column: common('building_name'), content: data?.buildingName },
    { column: common('arch_gubun'), content: data?.archGubun },
    { column: common('ldcg_name'), content: data?.ldcgName },
    { column: common('splot_name'), content: data?.splotName },
    { column: common('main_purpose'), content: data?.mainPurpose },
    { column: common('land_purpose'), content: data?.landPurpose },
    { column: common('district_purpose'), content: data?.districtPurpose },
    { column: common('zone_purpose'), content: data?.zonePurpose },
    {
      column: common('total_units'),
      content: formatTotalUnitsInfo(common, data?.hhldCnt, data?.hoCnt, data?.fmlyCnt),
    },
    { column: common('bc_rat'), content: formatVacancyRate(data?.bcRat) },
    { column: common('gra_py'), content: areaFormatter(data?.totalArea, { decimalRoundUnit: 'n2' }) },
    { column: common('combined_main_attachment_building_cnt'), content: `${data?.mainBldCnt}/${data?.atchBldCnt}` },
    {
      column: common('vl_rat_estm_tot_area'),
      content: areaFormatter(data?.vlRatEstmTotArea, { decimalRoundUnit: 'n2' }),
    },
    { column: common('plat_area'), content: areaFormatter(data?.platArea, { decimalRoundUnit: 'n2' }) },
    { column: common('vl_rat'), content: formatVacancyRate(data?.vlRat) },
    { column: common('architecture_area'), content: areaFormatter(data?.archArea, { decimalRoundUnit: 'n2' }) },
    { column: common('real_construction_date'), content: formatDateYmd(data?.realConstructionDateYmd) },
    {
      column: common('permission_construction_or_report_date'),
      content: formatDateYmd(data?.permissionConstructionDateYmd),
    },
    { column: common('delay_construction_date'), content: formatDateYmd(data?.delayConstructionDateYmd) },
    { column: common('use_approval_date'), content: formatDateYmd(data?.useAprDateYmd) },
    { column: common('scheduled_construction_date'), content: formatDateYmd(data?.scheduledConstructionDateYmd) },
  ]
  const additionalInfoData = [
    {
      column: common('maintenance_name'),
      content: (
        <Box display="flex" flexDirection="column">
          {data?.maintenanceNames?.map((name) => (
            <Box key={name} style={{ textAlign: 'right' }}>
              {name}
            </Box>
          ))}
        </Box>
      ),
      oneLine: true,
    },
  ]
  const buildingInfoData = [
    { column: common('main_purpose'), content: dongData?.mainPurpose },
    { column: common('main_atch_gb_cd_name'), content: dongData?.mainAtchGubun },
    { column: common('main_structure'), content: dongData?.mainStruct },
    {
      column: common('total_units'),
      content: formatTotalUnitsInfo(common, data?.hhldCnt, data?.hoCnt, data?.fmlyCnt),
    },
    {
      column: common('gra_py'),
      content: areaFormatter(dongData?.totalArea, { decimalRoundUnit: 'n2' }),
    },
    { column: common('roof_structure'), content: dongData?.roofStruct },
    {
      column: common('vl_rat_estm_tot_area'),
      content: areaFormatter(dongData?.vlRatEstmTotArea, { decimalRoundUnit: 'n2' }),
    },
    {
      column: common('architecture_area'),
      content: areaFormatter(dongData?.archArea, { decimalRoundUnit: 'n2' }),
    },
    {
      column: common('elevator_cnt'),
      content: `${unit('customer_elevator_cnt', { customer: dongData?.customerElevatorCnt })}, ${unit('emergency_elevator_cnt', { emergency: dongData?.emergencyElevatorCnt })}`,
    },
  ]
  const floorGridColumn: ColumnModel[] = [
    {
      field: 'floorNumber',
      headerText: common('floor'),
      valueAccessor: (field: string, data: any) => `${data.floorGubun} ${unit('floor', { number: data.floorNumber })}`,
    },
    {
      field: 'archGubun',
      headerText: common('architecture_division'),
      valueAccessor: useNilableValueAccessor,
    },
    {
      field: 'mainPurpose',
      headerText: common('main_purpose'),
      valueAccessor: useNilableValueAccessor,
    },
    {
      field: 'area',
      headerText: common('area'),
      valueAccessor: (field: string, data: any) => areaFormatter(data[field], { decimalRoundUnit: 'n2' }),
    },
  ]

  const permissionStepInfo = () => {
    switch (data?.permissionStep) {
      case '허가/신고': {
        if (data?.delayConstructionDateYmd) {
          return unit('permission_step', {
            step: data.permissionStep,
            dateType: common('delay_construction_date'),
            date: formatDateYmd(data.delayConstructionDateYmd),
          })
        }
        if (data?.scheduledConstructionDateYmd) {
          return unit('permission_step', {
            step: data.permissionStep,
            dateType: common('scheduled_construction_date'),
            date: formatDateYmd(data.scheduledConstructionDateYmd),
          })
        }
        return unit('permission_step', {
          step: data.permissionStep,
          dateType: common('permission_date'),
          date: formatDateYmd(data.permissionConstructionDateYmd),
        })
      }
      case '착공': {
        return unit('permission_step', {
          step: data.permissionStep,
          dateType: common('start_construction_date'),
          date: formatDateYmd(data.realConstructionDateYmd),
        })
      }
      case '사용승인': {
        return unit('permission_step', {
          step: data.permissionStep,
          dateType: common('use_approval_date'),
          date: formatDateYmd(data.useAprDateYmd),
        })
      }
      default: {
        return common('not_exists')
      }
    }
  }

  const headerSection = (
    <Box display="flex" flexDirection="column" gap="20px" p="0px 30px 20px">
      <Box display="flex" alignItems="center" gap="6px">
        <Separated with={<Box width="1px" height="14px" borderLeft="1px solid var(--color-gray-500)" />}>
          {data?.mainPurpose && (
            <Typography variant="subtitle" color="gray-800">
              {data.mainPurpose}
            </Typography>
          )}
          <Typography variant="subtitle" color="gray-800">
            {data?.archGubun ?? '-'}
          </Typography>
          <Typography variant="subtitle" color="gray-800">
            {data?.permissionStep ?? '-'}
          </Typography>
        </Separated>
      </Box>
      {pnu?.current && <LandInformationButton pnu={pnu.current} />}
    </Box>
  )

  const basicInfoSection = (
    <Box display="flex" flexDirection="column" gap="20px" p="24px 20px">
      <Box display="flex" alignItems="center" gap="30px" borderRadius="10px" p="25px 40px" backgroundColor="gray-100">
        <IconConstructBuilding width="49px" height="42px" />
        <Box display="flex" flex="1" flexDirection="column" gap="2px">
          <Typography variant="subtitle">{common('registration_status')}</Typography>
          <Typography variant="h5" fontWeight="semibold">
            {permissionStepInfo()}
          </Typography>
        </Box>
        <Button
          height="36px"
          content={permit('permit_notice.title')}
          color="guide"
          onClick={() => setVisible(true)}
          icon={<IconInformation width="12px" height="12px" color="var(--color-blue-700)" />}
        />
      </Box>
      <Box>
        <DetailTable data={basicInfoData} />
        {data?.archGubun === '대수선' && <DetailTable data={additionalInfoData} />}
      </Box>
      <DataSourceUpdate category="detail.building.permission" />
      <GuideDialog visible={visible} onClose={() => setVisible(false)}>
        <Box display="flex" flexDirection="column" gap="24px">
          <Box display="flex" flexDirection="column" gap="6px">
            <Typography variant="h5" fontWeight="semibold" color="blue-700">
              {permit('permit_notice.title')}
            </Typography>
            <Typography variant="subtitle">{permit('permit_notice.content')}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap="6px">
            <Typography variant="h5" fontWeight="semibold" color="blue-700">
              {permit('permit_type_notice.title')}
            </Typography>
            {permit('permit_type_notice.content', { returnObjects: true }).map((content: string, idx: number) => {
              return (
                <Box key={content} display="flex">
                  <GuideContentDot />
                  <Box>
                    <Trans i18nKey={permit(`permit_type_notice.content.${idx}`)} />
                  </Box>
                </Box>
              )
            })}
          </Box>
          <Box display="flex" flexDirection="column" gap="6px">
            <Typography variant="h5" fontWeight="semibold" color="blue-700">
              {permit('process_step_notice.title')}
            </Typography>
            <Box>
              <Trans
                i18nKey={permit('process_step_notice.subtitle')}
                components={{
                  a: (
                    <a
                      href="https://open.eais.go.kr/opnsvc/opnSvcInqireView.do?viewType=1#"
                      target="_blank"
                      aria-label="공공기관 사이트"
                    />
                  ),
                }}
              />
            </Box>
            {permitProcessContents.map((key: string, index: number) => {
              const pl = index === SUB_PARAGRAPH_INDEX ? '20px' : undefined
              return (
                <Box key={index} display="flex" pl={pl}>
                  <GuideContentDot />
                  <Trans i18nKey={key} />
                </Box>
              )
            })}
          </Box>
        </Box>
      </GuideDialog>
    </Box>
  )
  const detailSection = (
    <Box display="flex" flexDirection="column" gap="30px" p="24px 20px">
      <Box display="flex" flexDirection="column" gap="16px">
        <Typography variant="subtitle" fontWeight="semibold">
          {common('dong_summary')}
        </Typography>
        <ScrollableTabButtonBox>
          {dongList && (
            <TabButton
              items={dongList.map((dong) => {
                return { text: dong.dongLabel ?? '', value: dong.mgmDongOulnPk }
              })}
              selected={selectedDongPk}
              onSelect={handleOnSelectTab}
              size="sm"
            />
          )}
        </ScrollableTabButtonBox>
        <DetailTable data={buildingInfoData} />
      </Box>
      <Box display="flex" flexDirection="column" gap="16px">
        <Typography variant="subtitle" fontWeight="semibold">
          {common('floor_summary')}
        </Typography>
        <Box>
          <DataGrid
            dataSource={floorData?.data}
            columns={floorGridColumn}
            pagerModel={
              floorData && {
                pageSize: 10,
                currentPage: currentPage,
                totalRecordsCount: floorData?.page.totalSize,
              }
            }
            onPageChange={handlePageChange}
            paginationMode="server"
          />
        </Box>
      </Box>
    </Box>
  )

  return (
    <StyledDialog
      ref={ref}
      visible={false}
      width="1000px"
      target="#dialog-target"
      beforeOpen={beforeOpen}
      open={handleOpen}
      close={handleClose}
      position={{ X: 'center', Y: 'top' }}
      closeOnEscape={false}
      $disableOverlayClose={true}
      isModal
    >
      <FullDetailWrapper>
        {isLoading && <Indicator loading />}
        {!isLoading && dialogVisible && (
          <ScrollBox height="100%">
            <StickyBox top={0} p="24px 58px 20px 30px" backgroundColor="system-white">
              <Typography
                variant="h3"
                fontWeight="bold"
                color="gray-800"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {data?.address ?? '-'}
              </Typography>
              <Box position="absolute" top={20} right={20} onClick={handleOnClose} cursor="pointer">
                <IconClose width="16px" height="16px" color="var(--color-gray-700)" />
              </Box>
            </StickyBox>
            <Box display="flex" flexDirection="column">
              <Separated with={<FilterDivider />}>
                <Box>{headerSection}</Box>
                <Box>{basicInfoSection}</Box>
                <Box>{hasDongList && detailSection}</Box>
              </Separated>
              <DetailFooter />
            </Box>
          </ScrollBox>
        )}
        <Box
          key="fullButton"
          width="100%"
          position="absolute"
          bottom={0}
          p={5}
          backgroundColor="system-white"
          zIndex={DETAIL_FOOTER_BUTTONS_Z_INDEX}
        >
          <Button content={common('close')} size="lg" color="black" width="100%" onClick={handleOnClose} />
        </Box>
      </FullDetailWrapper>
    </StyledDialog>
  )
})

PermitDetailDialog.displayName = 'PermitDetailDialog'

const StyledDialog = styled(DialogComponent)`
  &.e-dialog {
    border: 0;
    border-radius: 0;

    .e-dlg-header-content {
      position: absolute;
      top: 0;
      right: -88px;
      padding: 0;
      border: 0 !important;
      border-radius: 0 !important;
    }

    .e-dlg-content {
      padding: 0;
    }
  }
`

const FullDetailWrapper = styled(Box)`
  height: 100vh;
  scroll-behavior: smooth;

  & > div::-webkit-scrollbar {
    display: none;
  }
`

export default PermitDetailDialog
