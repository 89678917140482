import { FC } from 'react'
import Box from '~/components/Box/Box'
import { SkeletonComponent } from '@syncfusion/ej2-react-notifications'
import { useDetailInfo } from '~/templates/DetailTypeProvider'

const LandSkeleton: FC = () => {
  const { detailType } = useDetailInfo()
  const isFull = detailType === 'full'

  return (
    <Box display="flex" flexDirection="column" backgroundColor="white">
      {/*info*/}
      <Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center" height="56px" gap="10px" padding="16px">
          <SkeletonComponent shape="Rectangle" width="26px" height="23px" />
          <SkeletonComponent shape="Rectangle" width="26px" height="23px" />
          <SkeletonComponent shape="Rectangle" width="26px" height="23px" />
        </Box>
        {isFull ? (
          <>
            <SkeletonComponent shape="Rectangle" width="100%" height="300px" />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
              gap="12px"
              height="204px"
              padding="24px 30px"
            >
              <SkeletonComponent shape="Rectangle" width="64px" height="24px" />
              <SkeletonComponent shape="Rectangle" width="313px" height="32px" />
              <Box marginTop="8px">
                <SkeletonComponent shape="Rectangle" width="724px" height="20px" />
              </Box>
            </Box>
          </>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            height="212px"
            gap="14px"
            padding="20px"
          >
            <SkeletonComponent shape="Rectangle" width="300px" height="32px" />
            <Box marginTop="20px" marginBottom="20px">
              <SkeletonComponent shape="Rectangle" width="250px" height="20px" />
              <SkeletonComponent shape="Rectangle" width="250px" height="20px" />
              <SkeletonComponent shape="Rectangle" width="250px" height="20px" />
            </Box>
          </Box>
        )}
        <Box display="flex">
          <SkeletonComponent shape="Rectangle" height="56px" />
          <SkeletonComponent shape="Rectangle" height="56px" />
          <SkeletonComponent shape="Rectangle" height="56px" />
          <SkeletonComponent shape="Rectangle" height="56px" />
          <SkeletonComponent shape="Rectangle" height="56px" />
        </Box>
      </Box>
      {/*content*/}
      <Box display="flex" flexDirection="column" gap="16px">
        {/*건물*/}
        <Box display="flex" flexDirection="column" padding="24px 20px" gap="20px">
          <SkeletonComponent shape="Text" width="80px" height="24px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="880px" />

          <SkeletonComponent shape="Text" width="80px" height="24px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="235px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="18px" />

          <SkeletonComponent shape="Text" width="100px" height="24px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="235px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="18px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="36px" />

          <SkeletonComponent shape="Rectangle" width="100%" height="52px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="52px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="16px" />
        </Box>
        {/*토지*/}
        <Box display="flex" flexDirection="column" padding="24px 20px" gap="20px">
          <SkeletonComponent shape="Rectangle" width="100%" height="1677px" />
        </Box>
        {/*임대차*/}
        <Box display="flex" flexDirection="column" padding="24px 20px" gap="20px">
          <SkeletonComponent shape="Rectangle" width="100%" height="405px" />
        </Box>
        {/*거래*/}
        <Box display="flex" flexDirection="column" padding="24px 20px" gap="30px">
          <SkeletonComponent shape="Rectangle" width="100%" height="569px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="517px" />
        </Box>
        {/*등기*/}
        <Box display="flex" flexDirection="column" padding="24px 20px" gap="30px">
          <SkeletonComponent shape="Rectangle" width="100%" height="888px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="150px" />
        </Box>
      </Box>
      {/*footer*/}
      <SkeletonComponent shape="Rectangle" width="100%" height="132px" />
    </Box>
  )
}

export default LandSkeleton
