import { FC } from 'react'
import { LedgerOutlineOut } from '~/libs/apis/data/model'
import FloorAndHoInfo from '~/templates/place/detail/common-data/FloorAndHoInfo'
import Box from '~/components/Box/Box'
import LedgerTable from '~/templates/place/detail/common-data/LedgerTable'

interface LedgerInfoProps {
  data: LedgerOutlineOut
}

const LedgerInfo: FC<LedgerInfoProps> = ({ data }) => {
  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <LedgerTable data={data} />
      <FloorAndHoInfo mgmBldrgstPk={data?.mgmBldrgstPk} />
    </Box>
  )
}

export default LedgerInfo
