import { FC, useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import styled from '@xstyled/styled-components'
import classNames from 'classnames'
import Box from '../Box/Box'

interface ItemProperties {
  left: number
  width: number
}

export interface ButtonGroupItem {
  value: string
  label: string
}

export interface ButtonGroupProps {
  name: string
  items: ButtonGroupItem[]
  onClick: (value: string) => void
  defaultValue?: string
}

const ButtonGroup: FC<ButtonGroupProps> = ({ name, items, onClick, defaultValue }) => {
  const buttonGroupRef = useRef<HTMLDivElement | null>(null)
  const [selectedValue, setSelectedValue] = useState(defaultValue ?? items[0].value)
  const [itemProperties, setItemProperties] = useState<ItemProperties[]>([])

  const currentIndex = items.findIndex((item) => item.value === selectedValue)

  const handleClick = (value: string) => {
    onClick(value)
    setSelectedValue(value)
  }

  useEffect(() => {
    if (buttonGroupRef.current) {
      // 각 버튼의 너비가 유동적이며 애니메이션 적용을 위해 각 버튼의 width, left 값 필요
      const buttonProperties: ItemProperties[] = []
      const containerElement = buttonGroupRef.current
      const buttonElements = containerElement.children[0].children

      for (const buttonElement of buttonElements) {
        const containerRect = containerElement.getBoundingClientRect()
        const buttonRect = buttonElement.children[0].getBoundingClientRect()

        buttonProperties.push({ left: buttonRect.left - containerRect.left, width: buttonRect.width })
      }

      setItemProperties(buttonProperties)
    }
  }, [name, items])

  return (
    <ButtonGroupWrapper ref={buttonGroupRef}>
      <Box display="flex">
        {items.map((item, index) => {
          return (
            <Box display="flex" alignItems="center" key={`${name}-button-group-${item.label}-${item.value}`}>
              <StyledButton
                onClick={() => handleClick(item.value)}
                className={`${classNames({ checked: item.value === selectedValue })}`}
              >
                {item.label}
              </StyledButton>
              {index !== items.length - 1 && <Divider index={index} currentIndex={currentIndex} />}
            </Box>
          )
        })}
      </Box>
      <Anchor
        width={itemProperties[currentIndex]?.width}
        left={itemProperties[currentIndex]?.left}
        transitionDuration={300}
        transitionProperty="width, left"
      />
    </ButtonGroupWrapper>
  )
}

const Divider = ({ index, currentIndex }: { index: number; currentIndex: number }) => (
  <motion.div
    initial={{ opacity: 1, width: '4px', display: 'flex', justifyContent: 'center' }}
    animate={{ opacity: index === currentIndex - 1 || index === currentIndex ? 0 : 1 }}
    transition={{ duration: 0.5 }}
  >
    <Box width="1px" height="16px" backgroundColor="gray-400" borderRadius="1px" />
  </motion.div>
)

const ButtonGroupWrapper = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  height: 36px;
  background-color: var(--color-gray-200);
  border-radius: 8px;
  padding: 2px;

  & {
    .checked {
      font-weight: semibold;
      color: var(--color-gray-800);
    }
  }
`

const StyledButton = styled.button`
  height: 100%;
  font-size: body;
  font-weight: semibold;
  padding: 0 16px;
  color: var(--color-gray-600);
  background-color: transparent;
  z-index: 1;
  transition: 0.3s ease;

  &:focus {
    outline: none;
  }
`

const Anchor = styled(Box)`
  position: absolute;
  height: 32px;
  border-radius: 6px;
  background-color: var(--color-system-white);
  box-shadow: black-01;
`

export default ButtonGroup
