/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.7.0
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  GetTenantsAggregationsClusterDataV1TenantsAggregationsClusterGetParams
} from '../model/getTenantsAggregationsClusterDataV1TenantsAggregationsClusterGetParams'
import type {
  GetTenantsAggregationsCountDataV1TenantsAggregationsCountGetParams
} from '../model/getTenantsAggregationsCountDataV1TenantsAggregationsCountGetParams'
import type {
  GetTenantsAggregationsDataV1TenantsAggregationsGetParams
} from '../model/getTenantsAggregationsDataV1TenantsAggregationsGetParams'
import type {
  GetTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGetParams
} from '../model/getTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGetParams'
import type {
  GetTenantsBuildingsMarkerDataV1TenantsBuildingsMarkerGetParams
} from '../model/getTenantsBuildingsMarkerDataV1TenantsBuildingsMarkerGetParams'
import type {
  GetTenantsStatusDataV1TenantsStatusGetParams
} from '../model/getTenantsStatusDataV1TenantsStatusGetParams'
import type {
  HTTPValidationError
} from '../model/hTTPValidationError'
import type {
  TenantsAggregationsClusterOut
} from '../model/tenantsAggregationsClusterOut'
import type {
  TenantsAggregationsCountOut
} from '../model/tenantsAggregationsCountOut'
import type {
  TenantsAggregationsDetailsPagingOut
} from '../model/tenantsAggregationsDetailsPagingOut'
import type {
  TenantsAggregationsPagingOut
} from '../model/tenantsAggregationsPagingOut'
import type {
  TenantsBuildingsMarkerOut
} from '../model/tenantsBuildingsMarkerOut'
import type {
  TenantsStatusOut
} from '../model/tenantsStatusOut'
import { raApiCall } from '../../../utils/customInstance';
import { customFormData } from '../../../utils/customFormData';


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary 임차정보 리스트(사용 테이블: ra_v2.mv_building_tenant_from_rtb)
 */
export const getTenantsAggregationsDataV1TenantsAggregationsGet = (
    params?: GetTenantsAggregationsDataV1TenantsAggregationsGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<TenantsAggregationsPagingOut>(
      {url: `/data/v1/tenants/aggregations`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetTenantsAggregationsDataV1TenantsAggregationsGetKey = (params?: GetTenantsAggregationsDataV1TenantsAggregationsGetParams,) => [`/data/v1/tenants/aggregations`, ...(params ? [params]: [])] as const;

    
export type GetTenantsAggregationsDataV1TenantsAggregationsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantsAggregationsDataV1TenantsAggregationsGet>>>
export type GetTenantsAggregationsDataV1TenantsAggregationsGetQueryError = HTTPValidationError

/**
 * @summary 임차정보 리스트(사용 테이블: ra_v2.mv_building_tenant_from_rtb)
 */
export const useGetTenantsAggregationsDataV1TenantsAggregationsGet = <TError = HTTPValidationError>(
 params?: GetTenantsAggregationsDataV1TenantsAggregationsGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getTenantsAggregationsDataV1TenantsAggregationsGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetTenantsAggregationsDataV1TenantsAggregationsGetKey(params) : null);
  const swrFn = () => getTenantsAggregationsDataV1TenantsAggregationsGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 임차정보 리스트 상세(사용 테이블: ra_v2.mv_building_tenant_from_rtb)
 */
export const getTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGet = (
    params?: GetTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<TenantsAggregationsDetailsPagingOut>(
      {url: `/data/v1/tenants/aggregations/details`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGetKey = (params?: GetTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGetParams,) => [`/data/v1/tenants/aggregations/details`, ...(params ? [params]: [])] as const;

    
export type GetTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGet>>>
export type GetTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGetQueryError = HTTPValidationError

/**
 * @summary 임차정보 리스트 상세(사용 테이블: ra_v2.mv_building_tenant_from_rtb)
 */
export const useGetTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGet = <TError = HTTPValidationError>(
 params?: GetTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGetKey(params) : null);
  const swrFn = () => getTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 임차인 탐색 카운트 (사용 테이블: ra_v2.mv_building_tenant_from_rtb)
 */
export const getTenantsAggregationsCountDataV1TenantsAggregationsCountGet = (
    params?: GetTenantsAggregationsCountDataV1TenantsAggregationsCountGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<TenantsAggregationsCountOut>(
      {url: `/data/v1/tenants/aggregations/count`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetTenantsAggregationsCountDataV1TenantsAggregationsCountGetKey = (params?: GetTenantsAggregationsCountDataV1TenantsAggregationsCountGetParams,) => [`/data/v1/tenants/aggregations/count`, ...(params ? [params]: [])] as const;

    
export type GetTenantsAggregationsCountDataV1TenantsAggregationsCountGetQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantsAggregationsCountDataV1TenantsAggregationsCountGet>>>
export type GetTenantsAggregationsCountDataV1TenantsAggregationsCountGetQueryError = HTTPValidationError

/**
 * @summary 임차인 탐색 카운트 (사용 테이블: ra_v2.mv_building_tenant_from_rtb)
 */
export const useGetTenantsAggregationsCountDataV1TenantsAggregationsCountGet = <TError = HTTPValidationError>(
 params?: GetTenantsAggregationsCountDataV1TenantsAggregationsCountGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getTenantsAggregationsCountDataV1TenantsAggregationsCountGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetTenantsAggregationsCountDataV1TenantsAggregationsCountGetKey(params) : null);
  const swrFn = () => getTenantsAggregationsCountDataV1TenantsAggregationsCountGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 임차인 탐색 클러스터 (사용 테이블: ra_v2.mv_building_tenant_from_rtb)
 */
export const getTenantsAggregationsClusterDataV1TenantsAggregationsClusterGet = (
    params: GetTenantsAggregationsClusterDataV1TenantsAggregationsClusterGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<TenantsAggregationsClusterOut[]>(
      {url: `/data/v1/tenants/aggregations/cluster`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetTenantsAggregationsClusterDataV1TenantsAggregationsClusterGetKey = (params: GetTenantsAggregationsClusterDataV1TenantsAggregationsClusterGetParams,) => [`/data/v1/tenants/aggregations/cluster`, ...(params ? [params]: [])] as const;

    
export type GetTenantsAggregationsClusterDataV1TenantsAggregationsClusterGetQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantsAggregationsClusterDataV1TenantsAggregationsClusterGet>>>
export type GetTenantsAggregationsClusterDataV1TenantsAggregationsClusterGetQueryError = HTTPValidationError

/**
 * @summary 임차인 탐색 클러스터 (사용 테이블: ra_v2.mv_building_tenant_from_rtb)
 */
export const useGetTenantsAggregationsClusterDataV1TenantsAggregationsClusterGet = <TError = HTTPValidationError>(
 params: GetTenantsAggregationsClusterDataV1TenantsAggregationsClusterGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getTenantsAggregationsClusterDataV1TenantsAggregationsClusterGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetTenantsAggregationsClusterDataV1TenantsAggregationsClusterGetKey(params) : null);
  const swrFn = () => getTenantsAggregationsClusterDataV1TenantsAggregationsClusterGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 임차인 탐색 빌딩 마커 (사용 테이블: ra_v2.mv_building_tenant_from_rtb)
 */
export const getTenantsBuildingsMarkerDataV1TenantsBuildingsMarkerGet = (
    params?: GetTenantsBuildingsMarkerDataV1TenantsBuildingsMarkerGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<TenantsBuildingsMarkerOut[]>(
      {url: `/data/v1/tenants/buildings/marker`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetTenantsBuildingsMarkerDataV1TenantsBuildingsMarkerGetKey = (params?: GetTenantsBuildingsMarkerDataV1TenantsBuildingsMarkerGetParams,) => [`/data/v1/tenants/buildings/marker`, ...(params ? [params]: [])] as const;

    
export type GetTenantsBuildingsMarkerDataV1TenantsBuildingsMarkerGetQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantsBuildingsMarkerDataV1TenantsBuildingsMarkerGet>>>
export type GetTenantsBuildingsMarkerDataV1TenantsBuildingsMarkerGetQueryError = HTTPValidationError

/**
 * @summary 임차인 탐색 빌딩 마커 (사용 테이블: ra_v2.mv_building_tenant_from_rtb)
 */
export const useGetTenantsBuildingsMarkerDataV1TenantsBuildingsMarkerGet = <TError = HTTPValidationError>(
 params?: GetTenantsBuildingsMarkerDataV1TenantsBuildingsMarkerGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getTenantsBuildingsMarkerDataV1TenantsBuildingsMarkerGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetTenantsBuildingsMarkerDataV1TenantsBuildingsMarkerGetKey(params) : null);
  const swrFn = () => getTenantsBuildingsMarkerDataV1TenantsBuildingsMarkerGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 임차인 탐색 임차면적 비율 (사용 테이블: ra_v2.mv_building_tenant_from_rtb)
 */
export const getTenantsStatusDataV1TenantsStatusGet = (
    params: GetTenantsStatusDataV1TenantsStatusGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<TenantsStatusOut[]>(
      {url: `/data/v1/tenants/status`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetTenantsStatusDataV1TenantsStatusGetKey = (params: GetTenantsStatusDataV1TenantsStatusGetParams,) => [`/data/v1/tenants/status`, ...(params ? [params]: [])] as const;

    
export type GetTenantsStatusDataV1TenantsStatusGetQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantsStatusDataV1TenantsStatusGet>>>
export type GetTenantsStatusDataV1TenantsStatusGetQueryError = HTTPValidationError

/**
 * @summary 임차인 탐색 임차면적 비율 (사용 테이블: ra_v2.mv_building_tenant_from_rtb)
 */
export const useGetTenantsStatusDataV1TenantsStatusGet = <TError = HTTPValidationError>(
 params: GetTenantsStatusDataV1TenantsStatusGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getTenantsStatusDataV1TenantsStatusGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetTenantsStatusDataV1TenantsStatusGetKey(params) : null);
  const swrFn = () => getTenantsStatusDataV1TenantsStatusGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

