/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.7.0
 */

export type Quarter = typeof Quarter[keyof typeof Quarter];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Quarter = {
  Q1: 'Q1',
  Q2: 'Q2',
  Q3: 'Q3',
  Q4: 'Q4',
} as const;
