/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.7.0
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  ClientOut
} from '../model/clientOut'
import type {
  ClientRegistrationsOwnershipPagingOut
} from '../model/clientRegistrationsOwnershipPagingOut'
import type {
  GetClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGetParams
} from '../model/getClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGetParams'
import type {
  HTTPValidationError
} from '../model/hTTPValidationError'
import { raApiCall } from '../../../utils/customInstance';
import { customFormData } from '../../../utils/customFormData';


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary 기업정보 (ra_v2.mv_client_info)
 */
export const getClientDataV1ClientsClientIdGet = (
    clientId: string,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<ClientOut>(
      {url: `/data/v1/clients/${clientId}`, method: 'GET'
    },
      options);
    }
  

export const getGetClientDataV1ClientsClientIdGetKey = (clientId: string,) => [`/data/v1/clients/${clientId}`] as const;

    
export type GetClientDataV1ClientsClientIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getClientDataV1ClientsClientIdGet>>>
export type GetClientDataV1ClientsClientIdGetQueryError = HTTPValidationError

/**
 * @summary 기업정보 (ra_v2.mv_client_info)
 */
export const useGetClientDataV1ClientsClientIdGet = <TError = HTTPValidationError>(
 clientId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getClientDataV1ClientsClientIdGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(clientId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetClientDataV1ClientsClientIdGetKey(clientId) : null);
  const swrFn = () => getClientDataV1ClientsClientIdGet(clientId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 기업 등기 소유 지분 (ra_v2.mv_client_info)
 */
export const getClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGet = (
    clientId: string,
    params?: GetClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<ClientRegistrationsOwnershipPagingOut>(
      {url: `/data/v1/clients/${clientId}/registrations/ownership`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGetKey = (clientId: string,
    params?: GetClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGetParams,) => [`/data/v1/clients/${clientId}/registrations/ownership`, ...(params ? [params]: [])] as const;

    
export type GetClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGetQueryResult = NonNullable<Awaited<ReturnType<typeof getClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGet>>>
export type GetClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGetQueryError = HTTPValidationError

/**
 * @summary 기업 등기 소유 지분 (ra_v2.mv_client_info)
 */
export const useGetClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGet = <TError = HTTPValidationError>(
 clientId: string,
    params?: GetClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(clientId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGetKey(clientId,params) : null);
  const swrFn = () => getClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGet(clientId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

