import { useTranslation } from 'next-i18next'
import { LwhOut } from '~/libs/apis/data/model'
import { formatVacancyRate } from '~/libs/utils/format'
import { ColumnModel } from '@syncfusion/ej2-react-grids'
import { StyledCompareChartGrid } from '~/templates/compare/lwh/content/chart/CompareLwhChartGrid'
import { useAreaFormat } from '~/libs/hooks/useFormat'
import { isNotNil } from '@toss/utils'

interface VacancyProps {
  data: LwhOut
}

const LwhVacancy = ({ data }: VacancyProps) => {
  const { t } = useTranslation()
  const { roomEmptyRate, roomStorageArea, roomEmptyArea, lowEmptyRate, lowStorageArea, lowEmptyArea } = data

  const areaFormatter = useAreaFormat()

  const formatStorageData = (num: NullishNumber) => {
    return areaFormatter(num, { decimalRoundUnit: 'n2' })
  }

  const dataSource = [
    {
      category: t('common_term.vacancy_rate'),
      roomTemperature: formatVacancyRate(roomEmptyRate),
      lowTemperature: formatVacancyRate(lowEmptyRate),
    },
    {
      category: t('common_term.storage_area'),
      roomTemperature: formatStorageData(roomStorageArea),
      lowTemperature: formatStorageData(lowStorageArea),
    },
    {
      category: t('common_term.vacancy_area'),
      roomTemperature: formatStorageData(roomEmptyArea),
      lowTemperature: formatStorageData(lowEmptyArea),
    },
  ]

  const columns: ColumnModel[] = [
    {
      field: 'category',
      headerText: '',
      textAlign: 'Left',
      valueAccessor: (field: string, data: any) => data[field] ?? '-',
    },
  ]

  if (isNotNil(roomEmptyRate) || isNotNil(roomStorageArea) || isNotNil(roomEmptyArea)) {
    columns.push({
      field: 'roomTemperature',
      headerText: t('common_term.room_temperature'),
      textAlign: 'Left',
      valueAccessor: (field: string, data: any) => data[field] ?? '-',
    })
  }

  if (isNotNil(lowEmptyRate) || isNotNil(lowStorageArea) || isNotNil(lowEmptyArea)) {
    columns.push({
      field: 'lowTemperature',
      headerText: t('common_term.low_temperature'),
      textAlign: 'Left',
      valueAccessor: (field: string, data: any) => data[field] ?? '-',
    })
  }

  return (
    <StyledCompareChartGrid
      dataSource={dataSource}
      columns={columns}
      allowPaging={false}
      allowSelection={false}
      loadingIndicator={{ indicatorType: 'Shimmer' }}
    />
  )
}

export default LwhVacancy
