/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.7.0
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  BuildingsCntDataV1BuildingsCountGetParams
} from '../model/buildingsCntDataV1BuildingsCountGetParams'
import type {
  BuildingsCntOut
} from '../model/buildingsCntOut'
import type {
  BuildingsDataV1BuildingsGetParams
} from '../model/buildingsDataV1BuildingsGetParams'
import type {
  BuildingsDetailsPagingOut
} from '../model/buildingsDetailsPagingOut'
import type {
  BuildingsMarkerDetailOut
} from '../model/buildingsMarkerDetailOut'
import type {
  BuildingsMarkerOut
} from '../model/buildingsMarkerOut'
import type {
  BuildingsPagingOut
} from '../model/buildingsPagingOut'
import type {
  ClusterDataV1BuildingsClusterGetParams
} from '../model/clusterDataV1BuildingsClusterGetParams'
import type {
  ClusterOut
} from '../model/clusterOut'
import type {
  GetBuildingsDetailsDataV1BuildingsDetailsGetParams
} from '../model/getBuildingsDetailsDataV1BuildingsDetailsGetParams'
import type {
  GetBuildingsMarkerDataV1BuildingsMarkerGetParams
} from '../model/getBuildingsMarkerDataV1BuildingsMarkerGetParams'
import type {
  GetBuildingsMarkerDetailDataV1BuildingsMarkerDetailGetParams
} from '../model/getBuildingsMarkerDetailDataV1BuildingsMarkerDetailGetParams'
import type {
  GroupOfficeOut
} from '../model/groupOfficeOut'
import type {
  HTTPValidationError
} from '../model/hTTPValidationError'
import { raApiCall } from '../../../utils/customInstance';
import { customFormData } from '../../../utils/customFormData';


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary 빌딩 리스트(사용 테이블: ra_v2.mv_buildings_detail)
 */
export const buildingsDataV1BuildingsGet = (
    params?: BuildingsDataV1BuildingsGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<BuildingsPagingOut>(
      {url: `/data/v1/buildings`, method: 'GET',
        params
    },
      options);
    }
  

export const getBuildingsDataV1BuildingsGetKey = (params?: BuildingsDataV1BuildingsGetParams,) => [`/data/v1/buildings`, ...(params ? [params]: [])] as const;

    
export type BuildingsDataV1BuildingsGetQueryResult = NonNullable<Awaited<ReturnType<typeof buildingsDataV1BuildingsGet>>>
export type BuildingsDataV1BuildingsGetQueryError = HTTPValidationError

/**
 * @summary 빌딩 리스트(사용 테이블: ra_v2.mv_buildings_detail)
 */
export const useBuildingsDataV1BuildingsGet = <TError = HTTPValidationError>(
 params?: BuildingsDataV1BuildingsGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof buildingsDataV1BuildingsGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBuildingsDataV1BuildingsGetKey(params) : null);
  const swrFn = () => buildingsDataV1BuildingsGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 빌딩 리스트 상세(테이블 뷰)(사용 테이블: ra_v2.mv_buildings_detail)
 */
export const getBuildingsDetailsDataV1BuildingsDetailsGet = (
    params?: GetBuildingsDetailsDataV1BuildingsDetailsGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<BuildingsDetailsPagingOut>(
      {url: `/data/v1/buildings/details`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetBuildingsDetailsDataV1BuildingsDetailsGetKey = (params?: GetBuildingsDetailsDataV1BuildingsDetailsGetParams,) => [`/data/v1/buildings/details`, ...(params ? [params]: [])] as const;

    
export type GetBuildingsDetailsDataV1BuildingsDetailsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getBuildingsDetailsDataV1BuildingsDetailsGet>>>
export type GetBuildingsDetailsDataV1BuildingsDetailsGetQueryError = HTTPValidationError

/**
 * @summary 빌딩 리스트 상세(테이블 뷰)(사용 테이블: ra_v2.mv_buildings_detail)
 */
export const useGetBuildingsDetailsDataV1BuildingsDetailsGet = <TError = HTTPValidationError>(
 params?: GetBuildingsDetailsDataV1BuildingsDetailsGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getBuildingsDetailsDataV1BuildingsDetailsGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetBuildingsDetailsDataV1BuildingsDetailsGetKey(params) : null);
  const swrFn = () => getBuildingsDetailsDataV1BuildingsDetailsGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 빌딩 리스트 카운트(사용 테이블: ra_v2.mv_buildings_detail)
 */
export const buildingsCntDataV1BuildingsCountGet = (
    params?: BuildingsCntDataV1BuildingsCountGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<BuildingsCntOut>(
      {url: `/data/v1/buildings/count`, method: 'GET',
        params
    },
      options);
    }
  

export const getBuildingsCntDataV1BuildingsCountGetKey = (params?: BuildingsCntDataV1BuildingsCountGetParams,) => [`/data/v1/buildings/count`, ...(params ? [params]: [])] as const;

    
export type BuildingsCntDataV1BuildingsCountGetQueryResult = NonNullable<Awaited<ReturnType<typeof buildingsCntDataV1BuildingsCountGet>>>
export type BuildingsCntDataV1BuildingsCountGetQueryError = HTTPValidationError

/**
 * @summary 빌딩 리스트 카운트(사용 테이블: ra_v2.mv_buildings_detail)
 */
export const useBuildingsCntDataV1BuildingsCountGet = <TError = HTTPValidationError>(
 params?: BuildingsCntDataV1BuildingsCountGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof buildingsCntDataV1BuildingsCountGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBuildingsCntDataV1BuildingsCountGetKey(params) : null);
  const swrFn = () => buildingsCntDataV1BuildingsCountGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 빌딩 리스트(사용 테이블: ra_v2.mv_buildings_detail)
 */
export const clusterDataV1BuildingsClusterGet = (
    params: ClusterDataV1BuildingsClusterGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<ClusterOut[]>(
      {url: `/data/v1/buildings/cluster`, method: 'GET',
        params
    },
      options);
    }
  

export const getClusterDataV1BuildingsClusterGetKey = (params: ClusterDataV1BuildingsClusterGetParams,) => [`/data/v1/buildings/cluster`, ...(params ? [params]: [])] as const;

    
export type ClusterDataV1BuildingsClusterGetQueryResult = NonNullable<Awaited<ReturnType<typeof clusterDataV1BuildingsClusterGet>>>
export type ClusterDataV1BuildingsClusterGetQueryError = HTTPValidationError

/**
 * @summary 빌딩 리스트(사용 테이블: ra_v2.mv_buildings_detail)
 */
export const useClusterDataV1BuildingsClusterGet = <TError = HTTPValidationError>(
 params: ClusterDataV1BuildingsClusterGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof clusterDataV1BuildingsClusterGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getClusterDataV1BuildingsClusterGetKey(params) : null);
  const swrFn = () => clusterDataV1BuildingsClusterGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 그룹 기본정보(사용 테이블: ra_v2.mv_buildings_group_info)
 */
export const officeGroupDataV1BuildingsGroupGroupRaIdGet = (
    groupRaId: string,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GroupOfficeOut>(
      {url: `/data/v1/buildings/group/${groupRaId}`, method: 'GET'
    },
      options);
    }
  

export const getOfficeGroupDataV1BuildingsGroupGroupRaIdGetKey = (groupRaId: string,) => [`/data/v1/buildings/group/${groupRaId}`] as const;

    
export type OfficeGroupDataV1BuildingsGroupGroupRaIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof officeGroupDataV1BuildingsGroupGroupRaIdGet>>>
export type OfficeGroupDataV1BuildingsGroupGroupRaIdGetQueryError = HTTPValidationError

/**
 * @summary 오피스 그룹 기본정보(사용 테이블: ra_v2.mv_buildings_group_info)
 */
export const useOfficeGroupDataV1BuildingsGroupGroupRaIdGet = <TError = HTTPValidationError>(
 groupRaId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof officeGroupDataV1BuildingsGroupGroupRaIdGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(groupRaId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOfficeGroupDataV1BuildingsGroupGroupRaIdGetKey(groupRaId) : null);
  const swrFn = () => officeGroupDataV1BuildingsGroupGroupRaIdGet(groupRaId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 빌딩 마커 리스트 (사용 테이블: ra_v2.mv_buildings_detail)
 */
export const getBuildingsMarkerDataV1BuildingsMarkerGet = (
    params?: GetBuildingsMarkerDataV1BuildingsMarkerGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<BuildingsMarkerOut[]>(
      {url: `/data/v1/buildings/marker`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetBuildingsMarkerDataV1BuildingsMarkerGetKey = (params?: GetBuildingsMarkerDataV1BuildingsMarkerGetParams,) => [`/data/v1/buildings/marker`, ...(params ? [params]: [])] as const;

    
export type GetBuildingsMarkerDataV1BuildingsMarkerGetQueryResult = NonNullable<Awaited<ReturnType<typeof getBuildingsMarkerDataV1BuildingsMarkerGet>>>
export type GetBuildingsMarkerDataV1BuildingsMarkerGetQueryError = HTTPValidationError

/**
 * @summary 빌딩 마커 리스트 (사용 테이블: ra_v2.mv_buildings_detail)
 */
export const useGetBuildingsMarkerDataV1BuildingsMarkerGet = <TError = HTTPValidationError>(
 params?: GetBuildingsMarkerDataV1BuildingsMarkerGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getBuildingsMarkerDataV1BuildingsMarkerGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetBuildingsMarkerDataV1BuildingsMarkerGetKey(params) : null);
  const swrFn = () => getBuildingsMarkerDataV1BuildingsMarkerGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 빌딩 마커 상세 리스트 (사용 테이블: ra_v2.mv_buildings_detail)
 */
export const getBuildingsMarkerDetailDataV1BuildingsMarkerDetailGet = (
    params?: GetBuildingsMarkerDetailDataV1BuildingsMarkerDetailGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<BuildingsMarkerDetailOut[]>(
      {url: `/data/v1/buildings/marker/detail`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetBuildingsMarkerDetailDataV1BuildingsMarkerDetailGetKey = (params?: GetBuildingsMarkerDetailDataV1BuildingsMarkerDetailGetParams,) => [`/data/v1/buildings/marker/detail`, ...(params ? [params]: [])] as const;

    
export type GetBuildingsMarkerDetailDataV1BuildingsMarkerDetailGetQueryResult = NonNullable<Awaited<ReturnType<typeof getBuildingsMarkerDetailDataV1BuildingsMarkerDetailGet>>>
export type GetBuildingsMarkerDetailDataV1BuildingsMarkerDetailGetQueryError = HTTPValidationError

/**
 * @summary 빌딩 마커 상세 리스트 (사용 테이블: ra_v2.mv_buildings_detail)
 */
export const useGetBuildingsMarkerDetailDataV1BuildingsMarkerDetailGet = <TError = HTTPValidationError>(
 params?: GetBuildingsMarkerDetailDataV1BuildingsMarkerDetailGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getBuildingsMarkerDetailDataV1BuildingsMarkerDetailGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetBuildingsMarkerDetailDataV1BuildingsMarkerDetailGetKey(params) : null);
  const swrFn = () => getBuildingsMarkerDetailDataV1BuildingsMarkerDetailGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

