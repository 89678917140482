import { FC, ReactNode } from 'react'
import { Box } from '~/components'
import InfoIcon from '~/assets/images/icons/info.svg'
import NoticeIcon from '~/assets/images/icons/notice.svg'
import GraphicNormal from '~/assets/images/graphic-normal.svg?url'
import GraphicNotice from '~/assets/images/graphic-notice.svg?url'
import Typography from '~/components/Typography/Typography'

interface NoticeBoxProps {
  type: 'normal' | 'notice'
  title?: string
  content: ReactNode | string
}

const NoticeBox: FC<NoticeBoxProps> = ({ type, title, content }) => {
  const isNormal = type === 'normal'
  const color = isNormal ? 'blue' : 'green'
  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      gap="10px"
      borderRadius="8px"
      padding="10px 14px"
      backgroundColor={`${color}-100`}
      {...(title && {
        backgroundImage: `url(${isNormal ? GraphicNormal : GraphicNotice})`,
        backgroundRepeat: 'repeat-y',
        objectFit: 'contain',
        backgroundPosition: 'top 0 right 0',
      })}
    >
      <Box
        display="flex"
        flexDirection={title ? 'column' : 'row'}
        alignItems={title ? undefined : 'center'}
        gap={title ? '10px' : undefined}
      >
        <Box display="flex" alignItems="center" gap={title ? '6px' : '10px'} color={`${color}-600`}>
          {isNormal ? <InfoIcon width="14px" height="14px" /> : <NoticeIcon width="14px" height="14px" />}
          <Typography fontWeight="bold" color="inherit">
            {title}
          </Typography>
        </Box>
        <Box zIndex="1">
          {typeof content === 'string' ? <Typography variant="body">{content}</Typography> : content}
        </Box>
      </Box>
    </Box>
  )
}

export default NoticeBox
