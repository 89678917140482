import { DialogComponent } from '@syncfusion/ej2-react-popups'
import styled from '@xstyled/styled-components'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '~/components'
import ScrollBox from '~/components/Box/ScrollBox'
import Button from '~/components/Button/Button'
import { commonDialogCss } from '~/components/Dialog/dialogStyle'
import Typography from '~/components/Typography/Typography'
import GuideGridTable from '~/templates/topic/lease-product/guide/GuideGridTable'

interface BoeAndSizeStandardNoticeDialogProps {
  visible: boolean
  isOffice: boolean
  close: () => void
}

const BoeAndSizeStandardNoticeDialog: FC<BoeAndSizeStandardNoticeDialogProps> = ({ visible, isOffice, close }) => {
  const { t } = useTranslation('common', { keyPrefix: 'guide_dialog' })
  const { t: commonTerm } = useTranslation('common', { keyPrefix: 'common_term' })
  const officeBoeStandard = t('office_boe_standard', { returnObjects: true })
  const officeSizeStandard = t('office_size_standard', { returnObjects: true })
  const lwhBoeStandard = t('lwh_boe_standard', { returnObjects: true })
  const lwhSizeStandard = t('lwh_size_standard', { returnObjects: true })
  const contentRef = useRef<HTMLDivElement>(null)

  const footerTemplate = () => (
    <Button content={commonTerm('close')} width="164px" size="lg" variant="line" color="gray" onClick={close} />
  )

  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
    }
  }

  const handleBeforeClose = () => {
    scrollToTop()
  }

  const officeBoeDataSource = [
    { item: commonTerm('cbd'), description: officeBoeStandard[0] },
    { item: commonTerm('gbd'), description: officeBoeStandard[1] },
    { item: commonTerm('ybd'), description: officeBoeStandard[2] },
    {
      item: commonTerm('seoul_etc'),
      description: officeBoeStandard[3],
    },
    { item: commonTerm('bbd'), description: officeBoeStandard[4] },
  ]

  const lwhBoeDataSource = [
    { item: commonTerm('capital_east_and_south'), description: lwhBoeStandard[0] },
    { item: commonTerm('capital_south'), description: lwhBoeStandard[1] },
    { item: commonTerm('capital_central'), description: lwhBoeStandard[2] },
    { item: commonTerm('capital_west'), description: lwhBoeStandard[3] },
    { item: commonTerm('capital_west_and_north'), description: lwhBoeStandard[4] },
    { item: commonTerm('capital_etc'), description: lwhBoeStandard[5] },
  ]

  const officeSizeDataSource = [
    { item: commonTerm('small'), description: officeSizeStandard[0] },
    { item: commonTerm('medium'), description: officeSizeStandard[1] },
    { item: commonTerm('medium_large'), description: officeSizeStandard[2] },
    { item: commonTerm('large'), description: officeSizeStandard[3] },
    { item: commonTerm('extra_large'), description: officeSizeStandard[4] },
  ]

  const lwhSizeDataSource = [
    { item: commonTerm('small'), description: lwhSizeStandard[0] },
    { item: commonTerm('medium'), description: lwhSizeStandard[1] },
    { item: commonTerm('large'), description: lwhSizeStandard[2] },
    { item: commonTerm('extra_large'), description: lwhSizeStandard[3] },
  ]

  return (
    <StyledDialog
      target="#dialog-target"
      width="560px"
      visible={visible}
      beforeClose={handleBeforeClose}
      closeOnEscape={false}
      footerTemplate={footerTemplate}
      isModal
    >
      <ScrollBox
        ref={contentRef}
        maxHeight={580}
        display="flex"
        flexDirection="column"
        gap="24px"
        p="30px"
        variant="vertical"
      >
        <Box>
          <Typography variant="h5" fontWeight="semibold" color="blue-700" pb="6px">
            {isOffice ? t('office_standard') : t('lwh_standard')}
          </Typography>
          <Typography variant="subtitle">{isOffice ? t('office_standard_guide') : t('lwh_standard_guide')}</Typography>
        </Box>
        <Box>
          <Typography variant="h5" fontWeight="semibold" color="blue-700" pb="6px">
            {t('boe_guide')}
          </Typography>
          <GuideGridTable
            columns={[t('boe'), t('boe_standard')]}
            contents={isOffice ? officeBoeDataSource : lwhBoeDataSource}
          />
        </Box>
        <Box>
          <Typography variant="h5" fontWeight="semibold" color="blue-700" pb="6px">
            {t('size_guide')}
          </Typography>
          <GuideGridTable
            columns={[t('size'), t('size_standard')]}
            contents={isOffice ? officeSizeDataSource : lwhSizeDataSource}
          />
        </Box>
      </ScrollBox>
    </StyledDialog>
  )
}

export default BoeAndSizeStandardNoticeDialog

const StyledDialog = styled(DialogComponent)`
  ${commonDialogCss}

  & {
    overflow: hidden;

    .e-dlg-content {
      padding: 0;
      background-color: var(--system-white);
    }
  }
`
