/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.7.0
 */

export type OfficeMarketLeaseMetricType = typeof OfficeMarketLeaseMetricType[keyof typeof OfficeMarketLeaseMetricType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfficeMarketLeaseMetricType = {
  rentFee: 'rentFee',
  noc: 'noc',
  emptyRate: 'emptyRate',
  emptyRateExcludeNewest: 'emptyRateExcludeNewest',
} as const;
