/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.7.0
 */

export type AnalysisOfficeLeaseMetricType = typeof AnalysisOfficeLeaseMetricType[keyof typeof AnalysisOfficeLeaseMetricType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnalysisOfficeLeaseMetricType = {
  rentFee: 'rentFee',
  maintenanceFee: 'maintenanceFee',
  noc: 'noc',
  emptyRate: 'emptyRate',
} as const;
