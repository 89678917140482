/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.7.0
 */

export type LwhDetailCompareLeaseMetricType = typeof LwhDetailCompareLeaseMetricType[keyof typeof LwhDetailCompareLeaseMetricType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LwhDetailCompareLeaseMetricType = {
  roomRentFee: 'roomRentFee',
  lowRentFee: 'lowRentFee',
  roomEmptyRate: 'roomEmptyRate',
  lowEmptyRate: 'lowEmptyRate',
  roomMaintenanceFee: 'roomMaintenanceFee',
  lowMaintenanceFee: 'lowMaintenanceFee',
} as const;
