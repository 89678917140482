/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.7.0
 */

export type ReportType = typeof ReportType[keyof typeof ReportType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportType = {
  pptx: 'pptx',
  pdf: 'pdf',
} as const;
