import { FC } from 'react'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import InquiryIcon from '~/assets/images/inquiry.svg'
import IconAngleRight from '~/assets/images/icons/angle-right.svg'
import { useTheme } from '@xstyled/styled-components'
import { ClickArea } from '@toss/react'
import useInquiryControl from '~/libs/hooks/useInquiryControl'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { useTranslation } from 'next-i18next'
import { InquiryItems } from '~/libs/utils/inquiry'
import Nodata from '~/components/NoData'

type DataType = 'lease_price' | 'lessee' // 임대시세 / 임차인
interface NoDataWithInquiryProps {
  type: DataType
  title?: string
  description?: string
}

const InquiryItemsForType: Record<DataType, InquiryItems[]> = {
  lease_price: ['LEASE_VACANCY'],
  lessee: ['LEASE_VACANCY'],
}
const NoDataWithInquiry: FC<NoDataWithInquiryProps> = ({ type, title, description }) => {
  const theme = useTheme()
  const { t } = useTranslation('common')
  const { detailContent, uid } = useDetailInfo()
  const { openDialog } = useInquiryControl()
  const handleClick = () => {
    openDialog({ type: detailContent, targetId: uid, inquiryItems: InquiryItemsForType[type] })
  }

  return (
    <Box width="100%" display="flex" flexDirection="column" gap="16px">
      <Nodata title={title} description={description} />
      <ClickArea onClick={handleClick}>
        <Box
          display="flex"
          borderRadius="10px"
          backgroundColor="purple-100"
          px="20px"
          py="16px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center" gap="10px">
            <InquiryIcon width="20px" height="20px" />
            <Typography fontWeight="600" color="purple-700">
              {t('no_data_with_inquiry.building_data_inquiry')}
            </Typography>
          </Box>
          <IconAngleRight width="12px" height="12px" color={theme.colors['purple-700']} />
        </Box>
      </ClickArea>
    </Box>
  )
}

export default NoDataWithInquiry
