/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.7.0
 */

export type IndexType = typeof IndexType[keyof typeof IndexType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IndexType = {
  building: 'building',
  region: 'region',
  land: 'land',
  subway: 'subway',
  registration_owner: 'registration_owner',
  client: 'client',
} as const;
