import { FC, useMemo } from 'react'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import { Page } from '~/libs/apis/data/model'
import LoadMoreDataGrid from '~/templates/place/detail/LoadMoreDataGrid'
import { useTranslation } from 'next-i18next'

interface SbizGridProps {
  dataSource: object[] | undefined
  pageModel: Page | undefined
  onPageChange: (page: number) => void
  isLoading?: boolean
}

const SbizGrid: FC<SbizGridProps> = ({ dataSource, pageModel, onPageChange, isLoading }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })

  const columns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'floor',
        headerText: t('floor'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 50,
        valueAccessor: (_: any, data: any) => data.floor ?? '-',
      },
      {
        field: 'lessee',
        headerText: t('tenant'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: (_: any, data: any) => data.lessee ?? '-',
      },
      {
        field: 'lesseeSector',
        headerText: t('sector'),
        ttextAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: (_: any, data: any) => data.lesseeSector ?? '-',
      },
    ],

    [t],
  )

  return (
    <LoadMoreDataGrid
      columns={columns}
      dataSource={dataSource}
      pageModel={pageModel}
      onPageChange={onPageChange}
      allowSelection={false}
      isLoading={isLoading}
    />
  )
}

export default SbizGrid
