import { FC } from 'react'
import { Box } from '~/components'
import Typography from '~/components/Typography/Typography'

interface LabelBadgeProps {
  label: string
}

const LabelBadge: FC<LabelBadgeProps> = ({ label }) => (
  <Box display="flex" backgroundColor="gray-200" padding="1px 6px" borderRadius="3px">
    <Typography variant="caption2" color="gray-700" fontWeight="semibold">
      {label}
    </Typography>
  </Box>
)

export default LabelBadge
