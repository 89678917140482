/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.7.0
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  GetLandClusterDataV1LandsClusterGetParams
} from '../model/getLandClusterDataV1LandsClusterGetParams'
import type {
  GetLandCntDataV1LandsCountGetParams
} from '../model/getLandCntDataV1LandsCountGetParams'
import type {
  GetLandDataV1LandsGetParams
} from '../model/getLandDataV1LandsGetParams'
import type {
  GetLandDetailsDataV1LandsDetailsGetParams
} from '../model/getLandDetailsDataV1LandsDetailsGetParams'
import type {
  GetLandMarkerDataV1LandsMarkerGetParams
} from '../model/getLandMarkerDataV1LandsMarkerGetParams'
import type {
  GetPermissionsDataV1LandsPermissionsGetParams
} from '../model/getPermissionsDataV1LandsPermissionsGetParams'
import type {
  GetPermissionsMarkerDataV1LandsPermissionsAggregationsMarkerGetParams
} from '../model/getPermissionsMarkerDataV1LandsPermissionsAggregationsMarkerGetParams'
import type {
  HTTPValidationError
} from '../model/hTTPValidationError'
import type {
  LandClusterOut
} from '../model/landClusterOut'
import type {
  LandCntOut
} from '../model/landCntOut'
import type {
  LandDetailPagingOut
} from '../model/landDetailPagingOut'
import type {
  LandLeaseProductsSummaryOut
} from '../model/landLeaseProductsSummaryOut'
import type {
  LandMarkerOut
} from '../model/landMarkerOut'
import type {
  LandPagingOut
} from '../model/landPagingOut'
import type {
  PermissionsMarkerOut
} from '../model/permissionsMarkerOut'
import type {
  PermissionsPagingOut
} from '../model/permissionsPagingOut'
import { raApiCall } from '../../../utils/customInstance';
import { customFormData } from '../../../utils/customFormData';


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary 토지 카운트
 */
export const getLandCntDataV1LandsCountGet = (
    params?: GetLandCntDataV1LandsCountGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LandCntOut>(
      {url: `/data/v1/lands/count`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetLandCntDataV1LandsCountGetKey = (params?: GetLandCntDataV1LandsCountGetParams,) => [`/data/v1/lands/count`, ...(params ? [params]: [])] as const;

    
export type GetLandCntDataV1LandsCountGetQueryResult = NonNullable<Awaited<ReturnType<typeof getLandCntDataV1LandsCountGet>>>
export type GetLandCntDataV1LandsCountGetQueryError = HTTPValidationError

/**
 * @summary 토지 카운트
 */
export const useGetLandCntDataV1LandsCountGet = <TError = HTTPValidationError>(
 params?: GetLandCntDataV1LandsCountGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getLandCntDataV1LandsCountGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetLandCntDataV1LandsCountGetKey(params) : null);
  const swrFn = () => getLandCntDataV1LandsCountGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 토지 클러스터
 */
export const getLandClusterDataV1LandsClusterGet = (
    params: GetLandClusterDataV1LandsClusterGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LandClusterOut[]>(
      {url: `/data/v1/lands/cluster`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetLandClusterDataV1LandsClusterGetKey = (params: GetLandClusterDataV1LandsClusterGetParams,) => [`/data/v1/lands/cluster`, ...(params ? [params]: [])] as const;

    
export type GetLandClusterDataV1LandsClusterGetQueryResult = NonNullable<Awaited<ReturnType<typeof getLandClusterDataV1LandsClusterGet>>>
export type GetLandClusterDataV1LandsClusterGetQueryError = HTTPValidationError

/**
 * @summary 토지 클러스터
 */
export const useGetLandClusterDataV1LandsClusterGet = <TError = HTTPValidationError>(
 params: GetLandClusterDataV1LandsClusterGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getLandClusterDataV1LandsClusterGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetLandClusterDataV1LandsClusterGetKey(params) : null);
  const swrFn = () => getLandClusterDataV1LandsClusterGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 토지 마커 리스트(default: 500건)
 */
export const getLandMarkerDataV1LandsMarkerGet = (
    params?: GetLandMarkerDataV1LandsMarkerGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LandMarkerOut[]>(
      {url: `/data/v1/lands/marker`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetLandMarkerDataV1LandsMarkerGetKey = (params?: GetLandMarkerDataV1LandsMarkerGetParams,) => [`/data/v1/lands/marker`, ...(params ? [params]: [])] as const;

    
export type GetLandMarkerDataV1LandsMarkerGetQueryResult = NonNullable<Awaited<ReturnType<typeof getLandMarkerDataV1LandsMarkerGet>>>
export type GetLandMarkerDataV1LandsMarkerGetQueryError = HTTPValidationError

/**
 * @summary 토지 마커 리스트(default: 500건)
 */
export const useGetLandMarkerDataV1LandsMarkerGet = <TError = HTTPValidationError>(
 params?: GetLandMarkerDataV1LandsMarkerGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getLandMarkerDataV1LandsMarkerGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetLandMarkerDataV1LandsMarkerGetKey(params) : null);
  const swrFn = () => getLandMarkerDataV1LandsMarkerGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 토지 목록
 */
export const getLandDataV1LandsGet = (
    params?: GetLandDataV1LandsGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LandPagingOut>(
      {url: `/data/v1/lands`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetLandDataV1LandsGetKey = (params?: GetLandDataV1LandsGetParams,) => [`/data/v1/lands`, ...(params ? [params]: [])] as const;

    
export type GetLandDataV1LandsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getLandDataV1LandsGet>>>
export type GetLandDataV1LandsGetQueryError = HTTPValidationError

/**
 * @summary 토지 목록
 */
export const useGetLandDataV1LandsGet = <TError = HTTPValidationError>(
 params?: GetLandDataV1LandsGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getLandDataV1LandsGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetLandDataV1LandsGetKey(params) : null);
  const swrFn = () => getLandDataV1LandsGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 토지 상세 목록(default: 500건)
 */
export const getLandDetailsDataV1LandsDetailsGet = (
    params?: GetLandDetailsDataV1LandsDetailsGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LandDetailPagingOut>(
      {url: `/data/v1/lands/details`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetLandDetailsDataV1LandsDetailsGetKey = (params?: GetLandDetailsDataV1LandsDetailsGetParams,) => [`/data/v1/lands/details`, ...(params ? [params]: [])] as const;

    
export type GetLandDetailsDataV1LandsDetailsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getLandDetailsDataV1LandsDetailsGet>>>
export type GetLandDetailsDataV1LandsDetailsGetQueryError = HTTPValidationError

/**
 * @summary 토지 상세 목록(default: 500건)
 */
export const useGetLandDetailsDataV1LandsDetailsGet = <TError = HTTPValidationError>(
 params?: GetLandDetailsDataV1LandsDetailsGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getLandDetailsDataV1LandsDetailsGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetLandDetailsDataV1LandsDetailsGetKey(params) : null);
  const swrFn = () => getLandDetailsDataV1LandsDetailsGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 토지 임대 매물 요약
 */
export const getLandLeaseProductsSummaryDataV1LandsPnuLeaseProductsSummaryGet = (
    pnu: string,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LandLeaseProductsSummaryOut>(
      {url: `/data/v1/lands/${pnu}/lease-products/summary`, method: 'GET'
    },
      options);
    }
  

export const getGetLandLeaseProductsSummaryDataV1LandsPnuLeaseProductsSummaryGetKey = (pnu: string,) => [`/data/v1/lands/${pnu}/lease-products/summary`] as const;

    
export type GetLandLeaseProductsSummaryDataV1LandsPnuLeaseProductsSummaryGetQueryResult = NonNullable<Awaited<ReturnType<typeof getLandLeaseProductsSummaryDataV1LandsPnuLeaseProductsSummaryGet>>>
export type GetLandLeaseProductsSummaryDataV1LandsPnuLeaseProductsSummaryGetQueryError = HTTPValidationError

/**
 * @summary 토지 임대 매물 요약
 */
export const useGetLandLeaseProductsSummaryDataV1LandsPnuLeaseProductsSummaryGet = <TError = HTTPValidationError>(
 pnu: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getLandLeaseProductsSummaryDataV1LandsPnuLeaseProductsSummaryGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(pnu)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetLandLeaseProductsSummaryDataV1LandsPnuLeaseProductsSummaryGetKey(pnu) : null);
  const swrFn = () => getLandLeaseProductsSummaryDataV1LandsPnuLeaseProductsSummaryGet(pnu, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 공공데이터 인허가 목록 (사용 테이블: out_kr.mv_building_permission_overview_theme)
 */
export const getPermissionsDataV1LandsPermissionsGet = (
    params?: GetPermissionsDataV1LandsPermissionsGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PermissionsPagingOut>(
      {url: `/data/v1/lands/permissions`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetPermissionsDataV1LandsPermissionsGetKey = (params?: GetPermissionsDataV1LandsPermissionsGetParams,) => [`/data/v1/lands/permissions`, ...(params ? [params]: [])] as const;

    
export type GetPermissionsDataV1LandsPermissionsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getPermissionsDataV1LandsPermissionsGet>>>
export type GetPermissionsDataV1LandsPermissionsGetQueryError = HTTPValidationError

/**
 * @summary 공공데이터 인허가 목록 (사용 테이블: out_kr.mv_building_permission_overview_theme)
 */
export const useGetPermissionsDataV1LandsPermissionsGet = <TError = HTTPValidationError>(
 params?: GetPermissionsDataV1LandsPermissionsGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getPermissionsDataV1LandsPermissionsGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetPermissionsDataV1LandsPermissionsGetKey(params) : null);
  const swrFn = () => getPermissionsDataV1LandsPermissionsGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 공공데이터 인허가 마커 (사용 테이블: out_kr.mv_building_permission_overview_theme)
 */
export const getPermissionsMarkerDataV1LandsPermissionsAggregationsMarkerGet = (
    params?: GetPermissionsMarkerDataV1LandsPermissionsAggregationsMarkerGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PermissionsMarkerOut[]>(
      {url: `/data/v1/lands/permissions/aggregations/marker`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetPermissionsMarkerDataV1LandsPermissionsAggregationsMarkerGetKey = (params?: GetPermissionsMarkerDataV1LandsPermissionsAggregationsMarkerGetParams,) => [`/data/v1/lands/permissions/aggregations/marker`, ...(params ? [params]: [])] as const;

    
export type GetPermissionsMarkerDataV1LandsPermissionsAggregationsMarkerGetQueryResult = NonNullable<Awaited<ReturnType<typeof getPermissionsMarkerDataV1LandsPermissionsAggregationsMarkerGet>>>
export type GetPermissionsMarkerDataV1LandsPermissionsAggregationsMarkerGetQueryError = HTTPValidationError

/**
 * @summary 공공데이터 인허가 마커 (사용 테이블: out_kr.mv_building_permission_overview_theme)
 */
export const useGetPermissionsMarkerDataV1LandsPermissionsAggregationsMarkerGet = <TError = HTTPValidationError>(
 params?: GetPermissionsMarkerDataV1LandsPermissionsAggregationsMarkerGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getPermissionsMarkerDataV1LandsPermissionsAggregationsMarkerGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetPermissionsMarkerDataV1LandsPermissionsAggregationsMarkerGetKey(params) : null);
  const swrFn = () => getPermissionsMarkerDataV1LandsPermissionsAggregationsMarkerGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

