import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import { useTranslation } from 'next-i18next'
import { useAreaValueAccessor, usePerUnitValueAccessor, useRentAreaValueAccessor } from '~/libs/hooks/useFormat'
import { PagerModel } from '@syncfusion/ej2-grids/src/pager/pager-model'
import useNilableValueAccessor from '~/components/DataGrid/hooks/useNilableValueAccessor'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import useCodeNameValueAccessor from '~/libs/hooks/useCodeNameValueAccessor'
import BuildingGrid from '~/templates/common/grid/BuildingGrid'
import { Page } from '~/libs/apis/data/model'
import { Box } from '~/components'
import Typography from '~/components/Typography/Typography'
import Tooltip from '~/components/Tooltip/Tooltip'
import InfoIcon from '~/assets/images/icons/info.svg'

interface OccupancyStatusGridProps {
  dataSource: object[] | undefined
  activeIndex: number | undefined
  setActiveIndex: Dispatch<SetStateAction<number | undefined>>
  pagerModel?: PagerModel
  page?: Page
  onPageChange: (object: { currentPage: number }) => void
}

const OccupancyStatusGrid: FC<OccupancyStatusGridProps> = ({
  dataSource,
  activeIndex,
  setActiveIndex,
  pagerModel,
  page,
  onPageChange,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: msg } = useTranslation('common', { keyPrefix: 'message' })
  const { areaUnit, currencyUnit } = useUserConfiguration()
  const areaValueAccessor = useAreaValueAccessor(areaUnit)
  const perUnitValueAccessor = usePerUnitValueAccessor(areaUnit, currencyUnit)
  const codeNameValueAccessor = useCodeNameValueAccessor()
  const rentAreaValueAccessor = useRentAreaValueAccessor(areaUnit)

  const columns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'buildingName',
        headerText: t('building_name'),
        textAlign: 'Left',
        width: 150,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'address',
        headerText: t('address'),
        textAlign: 'Left',
        width: 180,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'propertyTypeCode',
        headerText: t('property_type'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 85,
        valueAccessor: codeNameValueAccessor,
      },
      {
        field: 'totalArea',
        headerText: t('gra_py'),
        textAlign: 'Left',
        width: 110,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: areaValueAccessor({ decimalRoundUnit: 'n2' }),
      },
      {
        field: 'nocPerM2',
        headerText: t('noc'),
        width: 130,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: perUnitValueAccessor({ decimalRoundUnit: 'n2' }),
      },
      {
        field: 'floors',
        headerText: t('floor'),
        width: 130,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        headerTemplate: () => (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontWeight="semibold">{t('rent_area_py')}</Typography>
            <Tooltip opensOn="Hover" content={msg('rent_area_py_lease_part')} width="267px" align="right">
              <InfoIcon width="12px" height="12px" color="var(--color-gray-800)" />
            </Tooltip>
          </Box>
        ),
        field: 'rentArea',
        textAlign: 'Left',
        width: 110,
        valueAccessor: rentAreaValueAccessor({ roundUnit: 10 }),
        clipMode: 'EllipsisWithTooltip',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [areaUnit, currencyUnit, t],
  )

  return (
    <BuildingGrid
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      dataSource={dataSource}
      pagerModel={pagerModel}
      columns={columns}
      page={page}
      onPageChange={onPageChange}
    />
  )
}

export default OccupancyStatusGrid
