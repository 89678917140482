import {
  useDemolishDataV1GovDemolishGet,
  useGetLandUsePlanDataV1GovLandUsePlanGet,
  useGetPermissionsDataV1GovPermissionsGet,
  useGetRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGet,
  useGetRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGet,
  useGetRealTradeLandsDataV1GovRealTradeLandsGet,
  useLandChangeOwnerDataV1GovLandChangeOwnerGet,
  useLandInfoDataV1GovLandGet,
  useLandPriceDataV1GovLandPriceGet,
  useLedgerFloorDataV1GovLedgerFloorGet,
  useLedgerOutlineDataV1GovLedgerOutlineGet,
  useLedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet,
  useLedgerPossessionDataV1GovLedgerPossessionGet,
  usePermissionDataV1GovPermissionGet,
  useSbizDataV1GovSbizGet,
} from '~/libs/apis/data/gov/gov'
import { isNotNil } from '@toss/utils'
import {
  useGetChangeEvents,
  useGetRegistrationOwnershipInfo,
  useGetRegistrations,
  useGetRegistrationTrades,
  useIsBuilding,
} from '~/libs/apis/registration/api'
import { useGetLandDetailsDataV1LandsDetailsGet } from '~/libs/apis/data/land/land'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { useGetRegistrationsDataV1RegistrationsGet } from '~/libs/apis/data/registrations/registrations'
import { subMonths, format } from 'date-fns'
import { useGetLandDataV1AreaLandGet } from '~/libs/apis/data/area/area'
import usePnusRegistrations from '~/libs/hooks/usePnusRegistrations'

const pageParam = { pageNum: 1, pageSize: 5 }

const useLandFullDetailLoading = (pnu: string) => {
  const isFull = useDetailInfo().detailType === 'full'

  const { isLoading: isLandInfoLoading } = useLandInfoDataV1GovLandGet({ pnu }, { swr: { enabled: Boolean(pnu) } })
  const { isLoading: isDetailLoading } = useGetLandDetailsDataV1LandsDetailsGet(
    { pnus: pnu },
    { swr: { enabled: Boolean(pnu) } },
  )

  // 건물
  const { data: ledger, isLoading: isLedgerOutlineLoading } = useLedgerOutlineDataV1GovLedgerOutlineGet({ pnu: pnu })
  const ledgerMgmBldrgstPk = ledger?.data?.[0]?.mgmBldrgstPk
  const ledgerInfoParams = { mgmBldrgstPk: ledgerMgmBldrgstPk!, pageNum: 1, pageSize: 10 }
  const { isLoading: isLedgerOutlineSummaryLoading } = useLedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet(
    { pnu: pnu },
    { swr: { shouldRetryOnError: false } },
  )
  const { isLoading: isLedgerPossessionLoading } = useLedgerPossessionDataV1GovLedgerPossessionGet(ledgerInfoParams, {
    swr: {
      enabled: isNotNil(ledgerMgmBldrgstPk) && isFull,
    },
  })
  const { isLoading: isLedgerFloorLoading } = useLedgerFloorDataV1GovLedgerFloorGet(ledgerInfoParams, {
    swr: {
      enabled: isNotNil(ledgerMgmBldrgstPk) && isFull,
    },
  })
  const { isLoading: isPermissionLoading } = usePermissionDataV1GovPermissionGet(
    { pnu, ...pageParam, pageSize: 10 },
    {
      swr: {
        enabled: isFull,
      },
    },
  )
  const { isLoading: isDemolishLoading } = useDemolishDataV1GovDemolishGet(
    { pnu, pageNum: 1, pageSize: 10 },
    {
      swr: {
        enabled: isFull,
      },
    },
  )

  // 토지
  const { isLoading: isAreaLandLoading } = useGetLandDataV1AreaLandGet({ pnu }, { swr: { enabled: Boolean(pnu) } })
  const { isLoading: isLandUsePlanLoading } = useGetLandUsePlanDataV1GovLandUsePlanGet(
    { pnu },
    {
      swr: {
        enabled: isNotNil(pnu),
      },
    },
  )
  const { isLoading: isLandChangeOwnerLoading } = useLandChangeOwnerDataV1GovLandChangeOwnerGet(
    { pnu, ...pageParam },
    {
      swr: {
        enabled: isNotNil(pnu),
      },
    },
  )
  const { isLoading: isLandPriceChartLoading } = useLandPriceDataV1GovLandPriceGet(
    {
      pnu,
    },
    {
      swr: {
        enabled: isNotNil(pnu) && isFull,
      },
    },
  )
  const lastYear = subMonths(new Date(), 11)
  const { isLoading: isPermissionsLoading } = useGetPermissionsDataV1GovPermissionsGet({
    pnu: pnu,
    archGubuns: '신축,증축,대수선,개축,이전',
    minReferenceDateYm: format(lastYear, 'yyyyMM'),
  })

  // 임대차
  const { isLoading: isSbizLoading } = useSbizDataV1GovSbizGet({
    pnu,
    ...pageParam,
  })

  // 거래
  const { isLoading: isGeneralTradeLoading } = useGetRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGet({
    pnu,
    ...pageParam,
  })
  const { isLoading: isMultiOwnedTradeLoading } =
    useGetRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGet(
      {
        pnu,
      },
      {
        swr: {
          keepPreviousData: true,
        },
      },
    )
  const { isLoading: isLandRealTradeLoading } = useGetRealTradeLandsDataV1GovRealTradeLandsGet(
    {
      pnu,
      ...pageParam,
    },
    {
      swr: {
        keepPreviousData: true,
      },
    },
  )

  // 등기
  const { isLoading: isRegistrationsLoading } = useGetRegistrations(
    { pnu: pnu },
    {
      swr: { enabled: Boolean(pnu), shouldRetryOnError: false },
    },
  )
  const { isLoading: isBuildingLoading } = useIsBuilding({ pnu })

  const { isLoading: isRegistrationsGetLoading } = useGetRegistrationsDataV1RegistrationsGet(
    { pnus: pnu },
    {
      swr: { enabled: Boolean(pnu), shouldRetryOnError: false },
    },
  )

  const { data: registrations, isLoading: isPnusRegistrationsLoading } = usePnusRegistrations(pnu)
  const registrationId = registrations?.data?.[0]?.registrationId
  const registrationStringId = registrationId?.toString() ?? ''
  const hasRegistrationId = Boolean(registrationId)
  const { isLoading: isOwnershipInfoLoading } = useGetRegistrationOwnershipInfo(
    registrationStringId,
    {
      page: '1',
      size: '5',
    },
    {
      swr: {
        keepPreviousData: true,
        enabled: hasRegistrationId,
      },
    },
  )
  const { isLoading: isRegistrationTradeLoading } = useGetRegistrationTrades(
    registrationStringId,
    {
      page: '1',
      size: '5',
    },
    {
      swr: {
        keepPreviousData: true,
        enabled: hasRegistrationId,
      },
    },
  )
  const { isLoading: isChangeResultLoading } = useGetChangeEvents(
    { pnu, page: '1', size: '5' },
    { swr: { enabled: hasRegistrationId } },
  )

  return (
    isLandInfoLoading ||
    isDetailLoading ||
    isLedgerOutlineLoading ||
    isLedgerOutlineSummaryLoading ||
    isLedgerPossessionLoading ||
    isLedgerFloorLoading ||
    isPermissionLoading ||
    isDemolishLoading ||
    isAreaLandLoading ||
    isLandUsePlanLoading ||
    isLandChangeOwnerLoading ||
    isLandPriceChartLoading ||
    isPermissionsLoading ||
    isSbizLoading ||
    isGeneralTradeLoading ||
    isMultiOwnedTradeLoading ||
    isLandRealTradeLoading ||
    isRegistrationsLoading ||
    isBuildingLoading ||
    isRegistrationsGetLoading ||
    isPnusRegistrationsLoading ||
    isOwnershipInfoLoading ||
    isRegistrationTradeLoading ||
    isChangeResultLoading
  )
}

export default useLandFullDetailLoading
