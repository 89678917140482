import { SeriesBaseModel } from '@syncfusion/ej2-charts/src/chart/series/chart-series-model'
import { ZoomSettingsModel } from '@syncfusion/ej2-charts/src/chart/chart-model'
import {
  EmptyPointSettingsModel,
  FontModel,
  IAfterExportEventArgs,
  IExportEventArgs,
  IMouseEventArgs,
  ITextRenderEventArgs,
  LegendSettingsModel,
  TooltipSettingsModel,
} from '@syncfusion/ej2-charts'
import theme from '~/libs/theme'
import { fontFamily } from '~/libs/theme/fonts'
import {
  ChartComponent,
  ChartModel,
  DataLabelSettingsModel,
  IAxisLabelRenderEventArgs,
  IPointEventArgs,
  MajorGridLinesModel,
  MarkerSettingsModel,
} from '@syncfusion/ej2-react-charts'
import { AxisModel } from '@syncfusion/ej2-charts/src/chart/axis/axis-model'
import { ChartAnnotationSettingsModel } from '@syncfusion/ej2-charts/src/chart/model/chart-base-model'
import { EmitType } from '@syncfusion/ej2-base'
import { CSSProperties, Ref } from 'react'
import { ISharedTooltipRenderEventArgs } from '@syncfusion/ej2-charts/src/chart/model/chart-interface'

export interface ChartProps extends ChartWrapperProps {
  refCallback?: Ref<ChartComponent>
  title?: string
  series?: SeriesBaseModel[]
  primaryXAxis?: AxisModel
  primaryYAxis?: AxisModel
  annotations?: ChartAnnotationSettingsModel[]
  legendSettings?: LegendSettingsModel
  tooltipSettings?: TooltipSettingsModel
  beforeExport?: EmitType<IExportEventArgs>
  afterExport?: EmitType<IAfterExportEventArgs>
  pointClick?: EmitType<IPointEventArgs>
  chartMouseLeave?: EmitType<IMouseEventArgs>
  columnWidthInPixel?: number
  axisLabelRender?: EmitType<IAxisLabelRenderEventArgs>
  textRender?: EmitType<ITextRenderEventArgs>
  pointMove?: EmitType<IPointEventArgs>
  additionalAxis?: AxisModel
  sharedTooltipRender?: EmitType<ISharedTooltipRenderEventArgs>
}

export interface ChartWrapperProps {
  chartId: string
  isLoading?: boolean
  isCursorPointer?: boolean
  width?: CSSProperties['width']
  height?: CSSProperties['height']
  noData?: boolean
  recentFiveYearsOnly?: boolean
}

export const zoomSettings: ZoomSettingsModel = {
  enableMouseWheelZooming: false,
  enablePinchZooming: true,
  enableSelectionZooming: true,
  mode: 'XY',
  enableScrollbar: true,
  enablePan: true,
  showToolbar: false,
}

export const labelStyle: FontModel = {
  fontFamily: fontFamily,
  size: theme.texts.caption1.fontSize,
  color: theme.colors['gray-800'],
}

export const hideGridLine: MajorGridLinesModel = {
  width: 0,
}

export const dashedGridLine: MajorGridLinesModel = {
  dashArray: '2,2',
}

export const commonTooltipSettings: TooltipSettingsModel = {
  enable: true,
  textStyle: labelStyle,
  fill: '#ffffff',
  showNearestPoint: false,
}

export const reverseHeaderFormatToolTipSettings: TooltipSettingsModel = {
  header: '<b>${point.x}</b>',
  format: '${series.name} : <b>${point.y}</b>',
}

export const commonChartComponentProps: ChartModel = {
  chartArea: { border: { width: 0 } },
  width: '100%',
  height: '100%',
  useGroupingSeparator: true,
}

export const commonPrimaryXAxisSettings: AxisModel = {
  labelStyle: labelStyle,
  majorGridLines: hideGridLine,
}

export const commonPrimaryYAxisSettings: AxisModel = {
  minimum: 0,
  labelStyle: labelStyle,
  majorGridLines: dashedGridLine,
  labelIntersectAction: 'Hide',
}

export const commonLegendSettings: LegendSettingsModel = {
  position: 'Top',
  alignment: 'Far',
  textStyle: labelStyle,
  toggleVisibility: false,
  padding: 0,
  margin: { bottom: 10 },
}

export const commonMarkerSettings: MarkerSettingsModel = {
  visible: true,
  isFilled: true,
  height: 6,
  width: 6,
}

export const commonCircleMarkerSettings: MarkerSettingsModel = {
  ...commonMarkerSettings,
  shape: 'Circle',
}

export const commonEmptyPointSettings: EmptyPointSettingsModel = {
  mode: 'Drop',
}

export const DATA_LABEL_FOR_STACKED_BAR_CHARTS: DataLabelSettingsModel = {
  visible: true,
  name: 'totalValue',
  position: 'Outer',
  font: labelStyle,
}

export const DATA_LABEL_FOR_SINGLE_BAR_CHARTS: DataLabelSettingsModel = {
  visible: true,
  position: 'Auto',
  alignment: 'Far',
  font: labelStyle,
  border: { color: 'transparent', width: 1 },
}

export const yearQuarterXAxisSettings: AxisModel = {
  interval: 1,
  valueType: 'DateTimeCategory',
  labelFormat: 'yyyy',
  labelStyle: labelStyle,
  intervalType: 'Years',
  labelPlacement: 'OnTicks',
  labelIntersectAction: 'Rotate45',
}

export const OFFICE_SUPPLY_STATUS = {
  existingSupplyArea: 'chart-6',
  plannedSupplyArea: 'chart-3',
} as const

export const commonLineStyles = {
  width: 2,
  marker: commonCircleMarkerSettings,
}
