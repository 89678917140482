import React, { FC, useState } from 'react'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import { useTranslation } from 'next-i18next'
import TabButton from '~/components/TabButton/TabButton'
import { SwitchCase } from '@toss/react'
import Trade from '~/templates/place/detail/trade/Trade'
import OfficeTradeHistory from '~/templates/place/detail/trade/OfficeTradeHistory'
import LwhTradeHistory from '~/templates/place/detail/trade/LwhTradeHistory'
import DataSourceUpdate from '~/components/DataSource/DataSourceUpdate'

interface TradeHistoryProps {
  pnu: string | null
  type?: 'office' | 'lwh'
}

type TradeType = 'history' | 'trade'

const TradeHistory: FC<TradeHistoryProps> = ({ pnu, type = 'office' }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: detail } = useTranslation('common', { keyPrefix: 'detail.common_data' })
  const [selected, setSelected] = useState<TradeType>('history')
  const tabItems = [
    { text: t('rsquare_research'), value: 'history' },
    { text: detail('trade'), value: 'trade' },
  ]

  const handleSelected = (value: string) => {
    setSelected(value as TradeType)
  }
  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Typography fontWeight={600} variant="subtitle">
        {t('trade_history')}
      </Typography>
      {pnu && <TabButton items={tabItems} size="sm" onSelect={handleSelected} selected={selected} />}
      <SwitchCase
        caseBy={{
          history: (
            <>
              {type === 'office' ? <OfficeTradeHistory /> : <LwhTradeHistory />}
              <DataSourceUpdate category="detail.trade.rsquare" />
            </>
          ),
          trade: <Trade pnu={pnu} />,
        }}
        value={selected}
      />
    </Box>
  )
}

export default TradeHistory
