import { FC } from 'react'
import { x } from '@xstyled/styled-components'

interface GuideGridTableProps {
  columns: Array<string>
  contents: Array<Record<string, string>>
}

const GuideGridTable: FC<GuideGridTableProps> = ({ columns, contents }) => {
  return (
    <x.table width="100%" borderCollapse="collapse">
      <x.thead>
        <x.tr bg="#F3F4F6" border="1px solid #E5E8ED">
          {columns.map((col, colIndex) => (
            <x.th
              key={colIndex}
              w={colIndex === 0 ? '110px' : 'auto'}
              p="9px 10px"
              alignItems="center"
              textAlign="left"
              border="1px solid #E5E8ED"
              fontWeight="600"
            >
              {col}
            </x.th>
          ))}
        </x.tr>
      </x.thead>
      <x.tbody>
        {contents.map((row, rowIndex) => (
          <x.tr key={rowIndex} border="1px solid #E5E8ED">
            {Object.keys(row).map((key, cellIndex) => (
              <x.td
                key={cellIndex}
                p="8px 10px"
                alignItems="center"
                textAlign="left"
                bg={cellIndex === 0 ? '#F3F4F6' : '#FFF'}
                border="1px solid #E5E8ED"
                fontWeight={cellIndex === 0 ? '600' : 'normal'}
              >
                {row[key]}
              </x.td>
            ))}
          </x.tr>
        ))}
      </x.tbody>
    </x.table>
  )
}

export default GuideGridTable
