/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.7.0
 */

export type LeasesClusterType = typeof LeasesClusterType[keyof typeof LeasesClusterType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LeasesClusterType = {
  sido: 'sido',
  sigungu: 'sigungu',
  bjd: 'bjd',
  grid: 'grid',
} as const;
