/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.7.0
 */

export type OfficeLeaseTrendValueType = typeof OfficeLeaseTrendValueType[keyof typeof OfficeLeaseTrendValueType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfficeLeaseTrendValueType = {
  rentFee: 'rentFee',
  maintenanceFee: 'maintenanceFee',
  noc: 'noc',
  emptyRate: 'emptyRate',
  emptyArea: 'emptyArea',
  depositFee: 'depositFee',
} as const;
