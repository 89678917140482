import { isNotNil } from '@toss/utils'
import { format, max, parse } from 'date-fns'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '~/components'
import Typography from '~/components/Typography/Typography'
import { DEFAULT_LESSEE_UPDATE_DATE } from '~/libs/constants/place'

interface TenantDataSourceArray {
  tenantCheckDateYmd: string | null
}

interface TenantDataSourceProps {
  data: Array<TenantDataSourceArray> | undefined
}
const TenantDataSource: FC<TenantDataSourceProps> = ({ data }) => {
  const { t } = useTranslation('common', { keyPrefix: 'source' })
  const isTenantDataSource = !!data?.length
  const tenantCheckDateArray = data
    ?.filter((dataSource) => isNotNil(dataSource.tenantCheckDateYmd))
    .map((dataSource) => parse(dataSource.tenantCheckDateYmd!, 'yyyyMMdd', new Date()))
  const lastUpdateDate = tenantCheckDateArray?.length
    ? format(max(tenantCheckDateArray), 'yyyy-MM-dd')
    : format(parse(DEFAULT_LESSEE_UPDATE_DATE, 'yyyyMMdd', new Date()), 'yyyy-MM-dd')

  return (
    <Box textAlign="right">
      <Typography variant="caption1" color="gray-600" wordBreak="keep-all">
        {`${isTenantDataSource ? lastUpdateDate : ''} ${t('detail.3')} ${t('standard')}`}
      </Typography>
    </Box>
  )
}

export default TenantDataSource
