import { FC } from 'react'
import { Box } from '~/components'
import InformationExclamationIcon from '~/assets/images/icons/information-exclamation.svg'
import Typography from '~/components/Typography/Typography'
import { useTranslation } from 'react-i18next'

interface NoDataProps {
  title?: string
  description?: string
  height?: string
}

const Nodata: FC<NoDataProps> = ({ title, description, height = '160px' }) => {
  const { t } = useTranslation('common')
  const titleText = title ?? t('message.no_data')

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height={height}
      backgroundColor="system-white"
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
        <InformationExclamationIcon width="28px" height="28px" color="var(--color-gray-400)" />
        <Box display="flex" flexDirection="column" alignItems="center" gap="8px">
          <Typography color="gray-700" fontWeight="semibold" variant="body" textAlign="center" whiteSpace="pre-wrap">
            {titleText}
          </Typography>
          <Typography color="gray-600" variant="caption1" textAlign="center" whiteSpace="pre-wrap">
            {description}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Nodata
