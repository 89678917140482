import React, { forwardRef } from 'react'
import DataGrid from '~/components/DataGrid/DataGrid'
import { GridComponent } from '@syncfusion/ej2-react-grids'
import { CompareLwhLineChartProps } from '~/templates/compare/lwh/content/chart/CompareLwhLineChart'
import useLwhCompareChart from '~/templates/compare/lwh/content/chart/useLwhCompareChart'
import styled from '@xstyled/styled-components'

const CompareLwhChartGrid = forwardRef<GridComponent, CompareLwhLineChartProps>(({ compareType }, ref) => {
  const { column, dataSource } = useLwhCompareChart(compareType)

  if (column.length < 2 || dataSource.length === 0) {
    return null
  }

  return <StyledCompareChartGrid ref={ref} dataSource={dataSource} columns={column} allowExcelExport />
})

CompareLwhChartGrid.displayName = 'CompareLwhChartGrid'

export default CompareLwhChartGrid

export const StyledCompareChartGrid = styled(DataGrid)`
  &.e-grid {
    width: fit-content !important;
    // 컬럼 최소 너비 128px
    .e-content col,
    .e-headercontent col {
      min-width: 128px;
    }

    // 헤더 컬럼 너비 100px 고정
    .e-content col:first-child,
    .e-headercontent col:first-child {
      width: 100px;
      min-width: 100px;
    }

    .e-rowcell:first-child {
      background-color: var(--color-gray-200);
      font-weight: 600;
    }

    .e-rowcell .e-selectionbackground .e-active {
      background-color: var(--color-gray-200);
    }

    &.is-selection .e-gridcontent {
      tr.e-row:hover:nth-child(odd) {
        .e-rowcell {
          background-color: var(--color-gray-200) !important;
        }

        .e-rowcell.e-ellipsistooltip {
          background-color: var(--color-system-white) !important;
        }
      }

      tr.e-row:nth-child(odd) td.e-active {
        background-color: var(--color-gray-200) !important;
      }
    }

    .e-gridcontent {
      tr.e-row:hover {
        .e-rowcell:first-child {
          background-color: var(--color-gray-200) !important;
        }
      }
    }
  }
`
