/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.7.0
 */

export type PropertyTypeCode = typeof PropertyTypeCode[keyof typeof PropertyTypeCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyTypeCode = {
  PT01_1: 'PT01_1',
  PT01_2: 'PT01_2',
} as const;
