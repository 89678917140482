import { useTranslation } from 'next-i18next'
import { LwhOut } from '~/libs/apis/data/model'
import { ColumnModel } from '@syncfusion/ej2-react-grids'
import { StyledCompareChartGrid } from '~/templates/compare/lwh/content/chart/CompareLwhChartGrid'
import { usePerUnitFormat } from '~/libs/hooks/useFormat'
import { isNotNil } from '@toss/utils'

interface LwhTypicalFloorLeasePricesProps {
  data: LwhOut
}

const LwhTypicalFloorLeasePrices = ({ data }: LwhTypicalFloorLeasePricesProps) => {
  const { t } = useTranslation()
  const {
    roomDepositFeePerM2,
    roomRentFeePerM2,
    roomMaintenanceFeePerM2,
    lowDepositFeePerM2,
    lowRentFeePerM2,
    lowMaintenanceFeePerM2,
  } = data

  const unitFormatter = usePerUnitFormat()
  const formatWonPerPy = (num: NullishNumber) => {
    return unitFormatter(num, { format: 'won' })
  }

  const dataSource = [
    {
      category: t('common_term.deposit'),
      roomTemperature: formatWonPerPy(roomDepositFeePerM2),
      lowTemperature: formatWonPerPy(lowDepositFeePerM2),
    },
    {
      category: t('common_term.rent_fee'),
      roomTemperature: formatWonPerPy(roomRentFeePerM2),
      lowTemperature: formatWonPerPy(lowRentFeePerM2),
    },
    {
      category: t('common_term.maintenance_fee'),
      roomTemperature: formatWonPerPy(roomMaintenanceFeePerM2),
      lowTemperature: formatWonPerPy(lowMaintenanceFeePerM2),
    },
  ]

  const columns: ColumnModel[] = [
    {
      field: 'category',
      headerText: '',
      textAlign: 'Left',
      valueAccessor: (field: string, data: any) => data[field] ?? '-',
    },
  ]

  if (isNotNil(roomDepositFeePerM2) || isNotNil(roomRentFeePerM2) || isNotNil(roomMaintenanceFeePerM2)) {
    columns.push({
      field: 'roomTemperature',
      headerText: t('common_term.room_temperature'),
      textAlign: 'Left',
      valueAccessor: (field: string, data: any) => data[field] ?? '-',
    })
  }

  if (isNotNil(lowDepositFeePerM2) || isNotNil(lowRentFeePerM2) || isNotNil(lowMaintenanceFeePerM2)) {
    columns.push({
      field: 'lowTemperature',
      headerText: t('common_term.low_temperature'),
      textAlign: 'Left',
      valueAccessor: (field: string, data: any) => data[field] ?? '-',
    })
  }

  return (
    <StyledCompareChartGrid
      dataSource={dataSource}
      columns={columns}
      allowPaging={false}
      allowSelection={false}
      loadingIndicator={{ indicatorType: 'Shimmer' }}
    />
  )
}

export default LwhTypicalFloorLeasePrices
