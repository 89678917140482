import { FC, ReactNode, PropsWithChildren, CSSProperties } from 'react'
import { formatTextEllipsis } from '~/libs/utils/format'
import { SystemProps, ThemeColor } from '@xstyled/styled-components'
import BubbleWrap from '~/components/Map/Marker/BubbleWrap'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'

type MarkerColorType = 'purple' | 'blue' | 'green' | 'permissionsGreen' | 'permissionsGray' | 'leaseProductPurple'

interface CommonMarkerProps {
  colorType?: MarkerColorType
  headerText?: string | null
  width?: string
  leadingIcon?: ReactNode
  opacity?: string
  isActive?: boolean
  isEllipsis?: boolean
  whiteSpace?: CSSProperties['whiteSpace']
  textAlign?: SystemProps['textAlign']
}

const markerColorMap = {
  purple: {
    header: 'purple-300',
    content: 'system-white',
    active: 'purple-700',
    border: 'purple-500',
  },
  blue: {
    header: 'blue-300',
    content: 'system-white',
    active: 'blue-700',
    border: 'blue-500',
  },
  green: {
    header: 'green-200',
    content: 'system-white',
    active: 'green-700',
    border: 'green-500',
  },
  permissionsGreen: {
    header: 'green-700',
    content: 'system-white',
    active: 'green-700',
    border: 'system-white',
  },
  permissionsGray: {
    header: 'system-white',
    content: 'system-white',
    active: 'gray-700',
    border: 'system-white',
  },
  leaseProductPurple: {
    header: 'purple-200',
    content: 'system-white',
    active: 'purple-600',
    border: 'purple-500',
  },
}

const CommonMarker: FC<PropsWithChildren<CommonMarkerProps>> = ({
  headerText,
  isActive,
  children,
  leadingIcon,
  colorType = 'purple',
  width,
  isEllipsis,
  opacity,
  whiteSpace = 'nowrap',
  textAlign = 'center',
}) => {
  const colorMap = markerColorMap[colorType]

  const getBackgroundColor = () => {
    if (children) {
      return !isActive ? colorMap.header : colorMap.active
    } else {
      return !isActive ? 'system-white' : colorMap.active
    }
  }

  const getFontColor = () => {
    if (children) {
      return !isActive ? (colorMap.active as ThemeColor) : 'system-white'
    } else {
      return !isActive ? ('gray-800' as ThemeColor) : 'system-white'
    }
  }

  const getHeaderText = () => {
    if (isEllipsis && headerText) {
      return formatTextEllipsis(headerText, 6, 1)
    } else {
      return headerText
    }
  }

  return (
    <BubbleWrap
      width={width ?? '100%'}
      backgroundColor={!isActive ? colorMap.content : colorMap.active}
      borderColor={!isActive ? colorMap.border : colorMap.active}
      opacity={opacity}
    >
      <Box width="100%" display="flex" flexDirection="column">
        {headerText && (
          <Box
            width="100%"
            display="flex"
            padding={children ? '4px 5px' : '6px 10px'}
            justifyContent="center"
            alignItems="center"
            gap="3px"
            backgroundColor={getBackgroundColor()}
            opacity={opacity}
          >
            {leadingIcon && <Box>{leadingIcon}</Box>}
            <Typography
              w="fit-content"
              textAlign={textAlign}
              variant="caption1"
              whiteSpace={whiteSpace}
              overflowX="hidden"
              textOverflow="ellipsis"
              color={getFontColor()}
              fontWeight="600"
              lineHeight="18px"
              wordBreak="break-all"
            >
              {getHeaderText()}
            </Typography>
          </Box>
        )}
        {children && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            backgroundColor={!isActive ? colorMap.content : colorMap.active}
            opacity={opacity}
          >
            {children}
          </Box>
        )}
      </Box>
    </BubbleWrap>
  )
}

export default CommonMarker
