/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.7.0
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  CommonCodesOut
} from '../model/commonCodesOut'
import type {
  ExchangesOut
} from '../model/exchangesOut'
import type {
  GetRegionDataV1RegionGetParams
} from '../model/getRegionDataV1RegionGetParams'
import type {
  GetRegionsDataV1RegionsGetParams
} from '../model/getRegionsDataV1RegionsGetParams'
import type {
  HTTPValidationError
} from '../model/hTTPValidationError'
import type {
  RaVersionOut
} from '../model/raVersionOut'
import type {
  RegionOutOutput
} from '../model/regionOutOutput'
import type {
  RegionsOut
} from '../model/regionsOut'
import { raApiCall } from '../../../utils/customInstance';
import { customFormData } from '../../../utils/customFormData';


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Health check API
 */
export const healthCheckActuatorHealthGet = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<unknown>(
      {url: `/actuator/health`, method: 'GET'
    },
      options);
    }
  

export const getHealthCheckActuatorHealthGetKey = () => [`/actuator/health`] as const;

    
export type HealthCheckActuatorHealthGetQueryResult = NonNullable<Awaited<ReturnType<typeof healthCheckActuatorHealthGet>>>
export type HealthCheckActuatorHealthGetQueryError = unknown

/**
 * @summary Health check API
 */
export const useHealthCheckActuatorHealthGet = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof healthCheckActuatorHealthGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getHealthCheckActuatorHealthGetKey() : null);
  const swrFn = () => healthCheckActuatorHealthGet(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * Ra backend version
 * @summary RA backend version
 */
export const versionDataV1VersionGet = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RaVersionOut>(
      {url: `/data/v1/version`, method: 'GET'
    },
      options);
    }
  

export const getVersionDataV1VersionGetKey = () => [`/data/v1/version`] as const;

    
export type VersionDataV1VersionGetQueryResult = NonNullable<Awaited<ReturnType<typeof versionDataV1VersionGet>>>
export type VersionDataV1VersionGetQueryError = unknown

/**
 * @summary RA backend version
 */
export const useVersionDataV1VersionGet = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof versionDataV1VersionGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getVersionDataV1VersionGetKey() : null);
  const swrFn = () => versionDataV1VersionGet(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * RA 테이블 코드 값 조회(공공데이터 제외)
 * @summary 공통 코드 조회 (사용 테이블: ra_v2.d_code_master)
 */
export const commonCodesDataV1CommonCodesGet = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<CommonCodesOut[]>(
      {url: `/data/v1/common-codes`, method: 'GET'
    },
      options);
    }
  

export const getCommonCodesDataV1CommonCodesGetKey = () => [`/data/v1/common-codes`] as const;

    
export type CommonCodesDataV1CommonCodesGetQueryResult = NonNullable<Awaited<ReturnType<typeof commonCodesDataV1CommonCodesGet>>>
export type CommonCodesDataV1CommonCodesGetQueryError = unknown

/**
 * @summary 공통 코드 조회 (사용 테이블: ra_v2.d_code_master)
 */
export const useCommonCodesDataV1CommonCodesGet = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof commonCodesDataV1CommonCodesGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getCommonCodesDataV1CommonCodesGetKey() : null);
  const swrFn = () => commonCodesDataV1CommonCodesGet(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 환율 조회 (사용 테이블: out_kr.mv_exchanges)
 */
export const getExchangesDataV1ExchangesGet = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<ExchangesOut[]>(
      {url: `/data/v1/exchanges`, method: 'GET'
    },
      options);
    }
  

export const getGetExchangesDataV1ExchangesGetKey = () => [`/data/v1/exchanges`] as const;

    
export type GetExchangesDataV1ExchangesGetQueryResult = NonNullable<Awaited<ReturnType<typeof getExchangesDataV1ExchangesGet>>>
export type GetExchangesDataV1ExchangesGetQueryError = unknown

/**
 * @summary 환율 조회 (사용 테이블: out_kr.mv_exchanges)
 */
export const useGetExchangesDataV1ExchangesGet = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getExchangesDataV1ExchangesGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetExchangesDataV1ExchangesGetKey() : null);
  const swrFn = () => getExchangesDataV1ExchangesGet(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 네비게이션 지역 목록 조회 (사용 테이블: out_kr.mv_boundary_region)
 */
export const getRegionsDataV1RegionsGet = (
    params?: GetRegionsDataV1RegionsGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegionsOut[]>(
      {url: `/data/v1/regions`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegionsDataV1RegionsGetKey = (params?: GetRegionsDataV1RegionsGetParams,) => [`/data/v1/regions`, ...(params ? [params]: [])] as const;

    
export type GetRegionsDataV1RegionsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegionsDataV1RegionsGet>>>
export type GetRegionsDataV1RegionsGetQueryError = HTTPValidationError

/**
 * @summary 네비게이션 지역 목록 조회 (사용 테이블: out_kr.mv_boundary_region)
 */
export const useGetRegionsDataV1RegionsGet = <TError = HTTPValidationError>(
 params?: GetRegionsDataV1RegionsGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegionsDataV1RegionsGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegionsDataV1RegionsGetKey(params) : null);
  const swrFn = () => getRegionsDataV1RegionsGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 네비게이션 현 위치 조회 (사용 테이블: out_kr.mv_boundary_region)
 */
export const getRegionDataV1RegionGet = (
    params: GetRegionDataV1RegionGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegionOutOutput>(
      {url: `/data/v1/region`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegionDataV1RegionGetKey = (params: GetRegionDataV1RegionGetParams,) => [`/data/v1/region`, ...(params ? [params]: [])] as const;

    
export type GetRegionDataV1RegionGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegionDataV1RegionGet>>>
export type GetRegionDataV1RegionGetQueryError = HTTPValidationError

/**
 * @summary 네비게이션 현 위치 조회 (사용 테이블: out_kr.mv_boundary_region)
 */
export const useGetRegionDataV1RegionGet = <TError = HTTPValidationError>(
 params: GetRegionDataV1RegionGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegionDataV1RegionGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegionDataV1RegionGetKey(params) : null);
  const swrFn = () => getRegionDataV1RegionGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

