import { FC, useMemo, useRef } from 'react'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import LoadMoreDataGrid from '~/templates/place/detail/LoadMoreDataGrid'
import { Page, SchemasBuildingsBaseLesseeBase } from '~/libs/apis/data/model'
import { useTranslation } from 'next-i18next'
import useNilableValueAccessor from '~/components/DataGrid/hooks/useNilableValueAccessor'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { useAreaValueAccessor } from '~/libs/hooks/useFormat'
import Tooltip from '~/components/Tooltip/Tooltip'
import Typography from '~/components/Typography/Typography'
import InfoIcon from '~/assets/images/icons/info.svg'
import Box from '~/components/Box/Box'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import { TooltipComponent } from '@syncfusion/ej2-react-popups/src/tooltip'

interface LesseeInfoGridProps {
  dataSource: SchemasBuildingsBaseLesseeBase[] | undefined
  pageModel: Page | undefined
  onPageChange: (page: number) => void
  isLoading: boolean
}
const LesseeInfoGrid: FC<LesseeInfoGridProps> = ({ dataSource, pageModel, onPageChange, isLoading }) => {
  const { detailType } = useDetailInfo()
  const isFull = detailType === 'full'
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: message } = useTranslation('common', { keyPrefix: 'message' })
  const { areaUnit } = useUserConfiguration()
  const areaValueAccessor = useAreaValueAccessor(areaUnit)
  const leasePyAreaRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<TooltipComponent>(null)

  const columns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'lessee',
        headerText: t('tenant'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'lesseeSector',
        headerText: t('sector'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'floor',
        headerText: t('floor'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
    ],

    [t],
  )

  const fullColumns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'lessee',
        headerText: t('tenant'),
        textAlign: 'Left',
        width: 141,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'lesseeSector',
        headerText: t('sector'),
        textAlign: 'Left',
        width: 141,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'floor',
        headerText: t('floor'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'rentArea',
        textAlign: 'Left',
        headerTemplate: () => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onMouseOver={() => tooltipRef?.current?.open(leasePyAreaRef.current || undefined)}
            onMouseOut={() => tooltipRef?.current?.close()}
          >
            <Typography fontWeight="semibold">{t('rent_area_py')}</Typography>
            <Tooltip ref={tooltipRef} content={message('rent_area_py_info')} width="324px" align="right">
              <Box ref={leasePyAreaRef}>
                <InfoIcon width="12px" height="12px" color="var(--color-gray-800)" />
              </Box>
            </Tooltip>
          </Box>
        ),
        valueAccessor: areaValueAccessor({ decimalRoundUnit: 'n2', skipZero: true }),
      },
      {
        field: 'rentStartDate',
        headerText: t('rent_start_date'),
        headerTextAlign: 'Center',
        textAlign: 'Center',
        width: 140,
        valueAccessor: () => 'no data',
      },
      {
        field: 'rentEndDate',
        headerText: t('rent_end_date'),
        headerTextAlign: 'Center',
        textAlign: 'Center',
        width: 140,
        valueAccessor: () => 'no data',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )

  return (
    <LoadMoreDataGrid
      columns={isFull ? fullColumns : columns}
      dataSource={dataSource}
      pageModel={pageModel}
      onPageChange={onPageChange}
      enableInquiryOverlay={isFull}
      isLoading={isLoading}
    />
  )
}

export default LesseeInfoGrid
