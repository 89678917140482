/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.7.0
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  GetRegistrationBuildingInfoDataV1RegistrationsBuildingInfoGetParams
} from '../model/getRegistrationBuildingInfoDataV1RegistrationsBuildingInfoGetParams'
import type {
  GetRegistrationChangeEventsDataV1RegistrationsChangeEventsGetParams
} from '../model/getRegistrationChangeEventsDataV1RegistrationsChangeEventsGetParams'
import type {
  GetRegistrationOwnershipInfosDataV1RegistrationsRegistrationIdOwnershipInfosGetParams
} from '../model/getRegistrationOwnershipInfosDataV1RegistrationsRegistrationIdOwnershipInfosGetParams'
import type {
  GetRegistrationTradesDataV1RegistrationsRegistrationIdTradesGetParams
} from '../model/getRegistrationTradesDataV1RegistrationsRegistrationIdTradesGetParams'
import type {
  GetRegistrationsClusterDataV1RegistrationsClusterGetParams
} from '../model/getRegistrationsClusterDataV1RegistrationsClusterGetParams'
import type {
  GetRegistrationsCntDataV1RegistrationsCountGetParams
} from '../model/getRegistrationsCntDataV1RegistrationsCountGetParams'
import type {
  GetRegistrationsDataV1RegistrationsGetParams
} from '../model/getRegistrationsDataV1RegistrationsGetParams'
import type {
  GetRegistrationsMarkerDataV1RegistrationsMarkerGetParams
} from '../model/getRegistrationsMarkerDataV1RegistrationsMarkerGetParams'
import type {
  GetRegistrationsR3CommonCodeDataV1RegistrationsR3CommonCodeGetParams
} from '../model/getRegistrationsR3CommonCodeDataV1RegistrationsR3CommonCodeGetParams'
import type {
  HTTPValidationError
} from '../model/hTTPValidationError'
import type {
  RegistrationByPnuOut
} from '../model/registrationByPnuOut'
import type {
  RegistrationChangeEventPagingOut
} from '../model/registrationChangeEventPagingOut'
import type {
  RegistrationClusterOut
} from '../model/registrationClusterOut'
import type {
  RegistrationDownloadOut
} from '../model/registrationDownloadOut'
import type {
  RegistrationMarkerOut
} from '../model/registrationMarkerOut'
import type {
  RegistrationPnuMappingOut
} from '../model/registrationPnuMappingOut'
import type {
  RegistrationR3CommonCodeOut
} from '../model/registrationR3CommonCodeOut'
import type {
  RegistrationTradesPagingOut
} from '../model/registrationTradesPagingOut'
import type {
  RegistrationsCountOut
} from '../model/registrationsCountOut'
import type {
  RegistrationsOwnershipPagingOut
} from '../model/registrationsOwnershipPagingOut'
import type {
  RegistrationsPagingOut
} from '../model/registrationsPagingOut'
import { raApiCall } from '../../../utils/customInstance';
import { customFormData } from '../../../utils/customFormData';


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary 등기 리스트
 */
export const getRegistrationsDataV1RegistrationsGet = (
    params?: GetRegistrationsDataV1RegistrationsGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegistrationsPagingOut>(
      {url: `/data/v1/registrations`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegistrationsDataV1RegistrationsGetKey = (params?: GetRegistrationsDataV1RegistrationsGetParams,) => [`/data/v1/registrations`, ...(params ? [params]: [])] as const;

    
export type GetRegistrationsDataV1RegistrationsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationsDataV1RegistrationsGet>>>
export type GetRegistrationsDataV1RegistrationsGetQueryError = HTTPValidationError

/**
 * @summary 등기 리스트
 */
export const useGetRegistrationsDataV1RegistrationsGet = <TError = HTTPValidationError>(
 params?: GetRegistrationsDataV1RegistrationsGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationsDataV1RegistrationsGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationsDataV1RegistrationsGetKey(params) : null);
  const swrFn = () => getRegistrationsDataV1RegistrationsGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 등기 리스트 카운트
 */
export const getRegistrationsCntDataV1RegistrationsCountGet = (
    params?: GetRegistrationsCntDataV1RegistrationsCountGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegistrationsCountOut>(
      {url: `/data/v1/registrations/count`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegistrationsCntDataV1RegistrationsCountGetKey = (params?: GetRegistrationsCntDataV1RegistrationsCountGetParams,) => [`/data/v1/registrations/count`, ...(params ? [params]: [])] as const;

    
export type GetRegistrationsCntDataV1RegistrationsCountGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationsCntDataV1RegistrationsCountGet>>>
export type GetRegistrationsCntDataV1RegistrationsCountGetQueryError = HTTPValidationError

/**
 * @summary 등기 리스트 카운트
 */
export const useGetRegistrationsCntDataV1RegistrationsCountGet = <TError = HTTPValidationError>(
 params?: GetRegistrationsCntDataV1RegistrationsCountGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationsCntDataV1RegistrationsCountGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationsCntDataV1RegistrationsCountGetKey(params) : null);
  const swrFn = () => getRegistrationsCntDataV1RegistrationsCountGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 등기 리스트
 */
export const getRegistrationsClusterDataV1RegistrationsClusterGet = (
    params: GetRegistrationsClusterDataV1RegistrationsClusterGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegistrationClusterOut[]>(
      {url: `/data/v1/registrations/cluster`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegistrationsClusterDataV1RegistrationsClusterGetKey = (params: GetRegistrationsClusterDataV1RegistrationsClusterGetParams,) => [`/data/v1/registrations/cluster`, ...(params ? [params]: [])] as const;

    
export type GetRegistrationsClusterDataV1RegistrationsClusterGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationsClusterDataV1RegistrationsClusterGet>>>
export type GetRegistrationsClusterDataV1RegistrationsClusterGetQueryError = HTTPValidationError

/**
 * @summary 등기 리스트
 */
export const useGetRegistrationsClusterDataV1RegistrationsClusterGet = <TError = HTTPValidationError>(
 params: GetRegistrationsClusterDataV1RegistrationsClusterGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationsClusterDataV1RegistrationsClusterGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationsClusterDataV1RegistrationsClusterGetKey(params) : null);
  const swrFn = () => getRegistrationsClusterDataV1RegistrationsClusterGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 등기 마커 리스트
 */
export const getRegistrationsMarkerDataV1RegistrationsMarkerGet = (
    params?: GetRegistrationsMarkerDataV1RegistrationsMarkerGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegistrationMarkerOut[]>(
      {url: `/data/v1/registrations/marker`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegistrationsMarkerDataV1RegistrationsMarkerGetKey = (params?: GetRegistrationsMarkerDataV1RegistrationsMarkerGetParams,) => [`/data/v1/registrations/marker`, ...(params ? [params]: [])] as const;

    
export type GetRegistrationsMarkerDataV1RegistrationsMarkerGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationsMarkerDataV1RegistrationsMarkerGet>>>
export type GetRegistrationsMarkerDataV1RegistrationsMarkerGetQueryError = HTTPValidationError

/**
 * @summary 등기 마커 리스트
 */
export const useGetRegistrationsMarkerDataV1RegistrationsMarkerGet = <TError = HTTPValidationError>(
 params?: GetRegistrationsMarkerDataV1RegistrationsMarkerGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationsMarkerDataV1RegistrationsMarkerGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationsMarkerDataV1RegistrationsMarkerGetKey(params) : null);
  const swrFn = () => getRegistrationsMarkerDataV1RegistrationsMarkerGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 등기관련 공통코드 조회
 */
export const getRegistrationsR3CommonCodeDataV1RegistrationsR3CommonCodeGet = (
    params?: GetRegistrationsR3CommonCodeDataV1RegistrationsR3CommonCodeGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegistrationR3CommonCodeOut[]>(
      {url: `/data/v1/registrations/r3-common-code`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegistrationsR3CommonCodeDataV1RegistrationsR3CommonCodeGetKey = (params?: GetRegistrationsR3CommonCodeDataV1RegistrationsR3CommonCodeGetParams,) => [`/data/v1/registrations/r3-common-code`, ...(params ? [params]: [])] as const;

    
export type GetRegistrationsR3CommonCodeDataV1RegistrationsR3CommonCodeGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationsR3CommonCodeDataV1RegistrationsR3CommonCodeGet>>>
export type GetRegistrationsR3CommonCodeDataV1RegistrationsR3CommonCodeGetQueryError = HTTPValidationError

/**
 * @summary 등기관련 공통코드 조회
 */
export const useGetRegistrationsR3CommonCodeDataV1RegistrationsR3CommonCodeGet = <TError = HTTPValidationError>(
 params?: GetRegistrationsR3CommonCodeDataV1RegistrationsR3CommonCodeGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationsR3CommonCodeDataV1RegistrationsR3CommonCodeGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationsR3CommonCodeDataV1RegistrationsR3CommonCodeGetKey(params) : null);
  const swrFn = () => getRegistrationsR3CommonCodeDataV1RegistrationsR3CommonCodeGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 기열람 등기 목록 조회
 */
export const getRegistrationsByPnuDataV1RegistrationsByPnuPnuGet = (
    pnu: string,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegistrationByPnuOut[]>(
      {url: `/data/v1/registrations/by-pnu/${pnu}`, method: 'GET'
    },
      options);
    }
  

export const getGetRegistrationsByPnuDataV1RegistrationsByPnuPnuGetKey = (pnu: string,) => [`/data/v1/registrations/by-pnu/${pnu}`] as const;

    
export type GetRegistrationsByPnuDataV1RegistrationsByPnuPnuGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationsByPnuDataV1RegistrationsByPnuPnuGet>>>
export type GetRegistrationsByPnuDataV1RegistrationsByPnuPnuGetQueryError = HTTPValidationError

/**
 * @summary 기열람 등기 목록 조회
 */
export const useGetRegistrationsByPnuDataV1RegistrationsByPnuPnuGet = <TError = HTTPValidationError>(
 pnu: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationsByPnuDataV1RegistrationsByPnuPnuGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(pnu)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationsByPnuDataV1RegistrationsByPnuPnuGetKey(pnu) : null);
  const swrFn = () => getRegistrationsByPnuDataV1RegistrationsByPnuPnuGet(pnu, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 기열람 등기 목록 PDF 다운로드
 */
export const getRegistrationDownloadDataV1RegistrationsRegistrationIdDownloadGet = (
    registrationId: number,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegistrationDownloadOut>(
      {url: `/data/v1/registrations/${registrationId}/download`, method: 'GET'
    },
      options);
    }
  

export const getGetRegistrationDownloadDataV1RegistrationsRegistrationIdDownloadGetKey = (registrationId: number,) => [`/data/v1/registrations/${registrationId}/download`] as const;

    
export type GetRegistrationDownloadDataV1RegistrationsRegistrationIdDownloadGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationDownloadDataV1RegistrationsRegistrationIdDownloadGet>>>
export type GetRegistrationDownloadDataV1RegistrationsRegistrationIdDownloadGetQueryError = HTTPValidationError

/**
 * @summary 기열람 등기 목록 PDF 다운로드
 */
export const useGetRegistrationDownloadDataV1RegistrationsRegistrationIdDownloadGet = <TError = HTTPValidationError>(
 registrationId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationDownloadDataV1RegistrationsRegistrationIdDownloadGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(registrationId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationDownloadDataV1RegistrationsRegistrationIdDownloadGetKey(registrationId) : null);
  const swrFn = () => getRegistrationDownloadDataV1RegistrationsRegistrationIdDownloadGet(registrationId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 등기 소유자분현황 조회
 */
export const getRegistrationOwnershipInfosDataV1RegistrationsRegistrationIdOwnershipInfosGet = (
    registrationId: number,
    params?: GetRegistrationOwnershipInfosDataV1RegistrationsRegistrationIdOwnershipInfosGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegistrationsOwnershipPagingOut>(
      {url: `/data/v1/registrations/${registrationId}/ownership-infos`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegistrationOwnershipInfosDataV1RegistrationsRegistrationIdOwnershipInfosGetKey = (registrationId: number,
    params?: GetRegistrationOwnershipInfosDataV1RegistrationsRegistrationIdOwnershipInfosGetParams,) => [`/data/v1/registrations/${registrationId}/ownership-infos`, ...(params ? [params]: [])] as const;

    
export type GetRegistrationOwnershipInfosDataV1RegistrationsRegistrationIdOwnershipInfosGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationOwnershipInfosDataV1RegistrationsRegistrationIdOwnershipInfosGet>>>
export type GetRegistrationOwnershipInfosDataV1RegistrationsRegistrationIdOwnershipInfosGetQueryError = HTTPValidationError

/**
 * @summary 등기 소유자분현황 조회
 */
export const useGetRegistrationOwnershipInfosDataV1RegistrationsRegistrationIdOwnershipInfosGet = <TError = HTTPValidationError>(
 registrationId: number,
    params?: GetRegistrationOwnershipInfosDataV1RegistrationsRegistrationIdOwnershipInfosGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationOwnershipInfosDataV1RegistrationsRegistrationIdOwnershipInfosGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(registrationId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationOwnershipInfosDataV1RegistrationsRegistrationIdOwnershipInfosGetKey(registrationId,params) : null);
  const swrFn = () => getRegistrationOwnershipInfosDataV1RegistrationsRegistrationIdOwnershipInfosGet(registrationId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 등기 매매 목록 조회 
 */
export const getRegistrationTradesDataV1RegistrationsRegistrationIdTradesGet = (
    registrationId: number,
    params?: GetRegistrationTradesDataV1RegistrationsRegistrationIdTradesGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegistrationTradesPagingOut>(
      {url: `/data/v1/registrations/${registrationId}/trades`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegistrationTradesDataV1RegistrationsRegistrationIdTradesGetKey = (registrationId: number,
    params?: GetRegistrationTradesDataV1RegistrationsRegistrationIdTradesGetParams,) => [`/data/v1/registrations/${registrationId}/trades`, ...(params ? [params]: [])] as const;

    
export type GetRegistrationTradesDataV1RegistrationsRegistrationIdTradesGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationTradesDataV1RegistrationsRegistrationIdTradesGet>>>
export type GetRegistrationTradesDataV1RegistrationsRegistrationIdTradesGetQueryError = HTTPValidationError

/**
 * @summary 등기 매매 목록 조회 
 */
export const useGetRegistrationTradesDataV1RegistrationsRegistrationIdTradesGet = <TError = HTTPValidationError>(
 registrationId: number,
    params?: GetRegistrationTradesDataV1RegistrationsRegistrationIdTradesGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationTradesDataV1RegistrationsRegistrationIdTradesGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(registrationId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationTradesDataV1RegistrationsRegistrationIdTradesGetKey(registrationId,params) : null);
  const swrFn = () => getRegistrationTradesDataV1RegistrationsRegistrationIdTradesGet(registrationId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 등기 관리 건물 여부
 */
export const getRegistrationBuildingInfoDataV1RegistrationsBuildingInfoGet = (
    params: GetRegistrationBuildingInfoDataV1RegistrationsBuildingInfoGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegistrationPnuMappingOut>(
      {url: `/data/v1/registrations/building-info`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegistrationBuildingInfoDataV1RegistrationsBuildingInfoGetKey = (params: GetRegistrationBuildingInfoDataV1RegistrationsBuildingInfoGetParams,) => [`/data/v1/registrations/building-info`, ...(params ? [params]: [])] as const;

    
export type GetRegistrationBuildingInfoDataV1RegistrationsBuildingInfoGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationBuildingInfoDataV1RegistrationsBuildingInfoGet>>>
export type GetRegistrationBuildingInfoDataV1RegistrationsBuildingInfoGetQueryError = HTTPValidationError

/**
 * @summary 등기 관리 건물 여부
 */
export const useGetRegistrationBuildingInfoDataV1RegistrationsBuildingInfoGet = <TError = HTTPValidationError>(
 params: GetRegistrationBuildingInfoDataV1RegistrationsBuildingInfoGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationBuildingInfoDataV1RegistrationsBuildingInfoGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationBuildingInfoDataV1RegistrationsBuildingInfoGetKey(params) : null);
  const swrFn = () => getRegistrationBuildingInfoDataV1RegistrationsBuildingInfoGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 등기 변동정보 조회
 */
export const getRegistrationChangeEventsDataV1RegistrationsChangeEventsGet = (
    params: GetRegistrationChangeEventsDataV1RegistrationsChangeEventsGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegistrationChangeEventPagingOut>(
      {url: `/data/v1/registrations/change-events`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegistrationChangeEventsDataV1RegistrationsChangeEventsGetKey = (params: GetRegistrationChangeEventsDataV1RegistrationsChangeEventsGetParams,) => [`/data/v1/registrations/change-events`, ...(params ? [params]: [])] as const;

    
export type GetRegistrationChangeEventsDataV1RegistrationsChangeEventsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationChangeEventsDataV1RegistrationsChangeEventsGet>>>
export type GetRegistrationChangeEventsDataV1RegistrationsChangeEventsGetQueryError = HTTPValidationError

/**
 * @summary 등기 변동정보 조회
 */
export const useGetRegistrationChangeEventsDataV1RegistrationsChangeEventsGet = <TError = HTTPValidationError>(
 params: GetRegistrationChangeEventsDataV1RegistrationsChangeEventsGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationChangeEventsDataV1RegistrationsChangeEventsGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationChangeEventsDataV1RegistrationsChangeEventsGetKey(params) : null);
  const swrFn = () => getRegistrationChangeEventsDataV1RegistrationsChangeEventsGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

