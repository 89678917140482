import React, { FC, useState } from 'react'
import Box from '~/components/Box/Box'
import { useTranslation } from 'next-i18next'
import { usePermissionDataV1GovPermissionGet } from '~/libs/apis/data/gov/gov'
import PermissionGrid from '~/templates/place/detail/common-data/PermissionGrid'
import DataSourceUpdate from '~/components/DataSource/DataSourceUpdate'
import Button from '~/components/Button/Button'
import { PermissionDataV1GovPermissionGetParams } from '~/libs/apis/data/model'
import NoticeBox from '~/components/NoticeBox/NoticeBox'
import Nodata from '~/components/NoData'

interface PermissionProps {
  pnu: string
  onClose: () => void
}

const PAGE_SIZE = 10

const Permission: FC<PermissionProps> = ({ pnu, onClose }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: message } = useTranslation('common', { keyPrefix: 'message' })
  const [params, setParams] = useState<PermissionDataV1GovPermissionGetParams>({
    pnu,
    pageNum: 1,
    pageSize: PAGE_SIZE,
  })
  const { data } = usePermissionDataV1GovPermissionGet(params, {
    swr: {
      keepPreviousData: true,
    },
  })

  const page = data?.page
  const pagerModel = page
    ? {
        pageSize: PAGE_SIZE,
        currentPage: page.page,
        totalRecordsCount: page.totalSize,
      }
    : undefined

  const handlePageChange = (object: { currentPage: number }) =>
    setParams((prev) => ({ ...prev, pageNum: object.currentPage }))

  return (
    <Box display="flex" flexDirection="column" gap="24px" pb={6}>
      <NoticeBox type="normal" content={message('permission_info')} />
      {page?.totalSize === 0 ? (
        <Nodata />
      ) : (
        <Box>
          <PermissionGrid dataSource={data?.data} pagerModel={pagerModel} onPageChange={handlePageChange} />
        </Box>
      )}
      <DataSourceUpdate category="detail.building.permission" />
      <Button color="gray" content={t('fold')} onClick={onClose} />
    </Box>
  )
}

export default Permission
