import crypto from 'crypto'
import * as ChannelService from '@channel.io/channel-web-sdk-loader'
import { isNotNil, isServer } from '@toss/utils'
import { CHANNELTALK_Z_INDEX } from '~/libs/constants/common'

export const canChannel = () => {
  // check only client and production
  if (process.env.NODE_ENV !== 'production' || isServer() || isNotNil(window.Cypress)) {
    return false
  }

  // service need channel env var
  if (isNotNil(process.env.CHANNELTALK_PLUGIN_KEY) && isNotNil(process.env.CHANNELTALK_SECRET_KEY)) {
    return true
  }
}

export const initChannel = () => {
  if (canChannel()) {
    ChannelService.loadScript()
  }
}

export const bootChannel = (memberId: string, profile: ChannelService.Profile) => {
  if (canChannel()) {
    const hash = crypto
      .createHmac('sha256', Buffer.from(process.env.CHANNELTALK_SECRET_KEY!, 'hex'))
      .update(memberId)
      .digest('hex')
    ChannelService.boot({
      pluginKey: process.env.CHANNELTALK_PLUGIN_KEY!,
      memberId,
      memberHash: hash,
      profile,
      zIndex: CHANNELTALK_Z_INDEX,
    })
  }
}
