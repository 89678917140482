import { forwardRef, PropsWithChildren, useDeferredValue, useEffect, useRef } from 'react'
import Box from '~/components/Box/Box'
import { LandingContent } from '~/templates/FullDetailDialogProvider'
import { SwitchCase, useCombinedRefs, useIsMounted } from '@toss/react'
import * as CSS from 'csstype'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import useOfficeFullDetailLoading from '~/templates/place/detail/useOfficeFullDetailLoading'
import { isNotNil } from '@toss/utils'
import useLwhFullDetailLoading from '~/templates/place/detail/useLwhFullDetailLoading'
import useLandFullDetailLoading from '~/templates/place/detail/useLandFullDetailLoading'
import useMapType from '~/libs/hooks/useMapType'
import usePnusRegistrations from '~/libs/hooks/usePnusRegistrations'

interface SectionProps {
  landingContent?: LandingContent
  scrollMarginTop: CSS.Property.ScrollMarginTop
}

const SectionBox = forwardRef<HTMLDivElement, PropsWithChildren<SectionProps>>(({ children, scrollMarginTop }, ref) => (
  <Box
    ref={ref}
    padding="24px 20px"
    display="flex"
    flexDirection="column"
    gap={4}
    backgroundColor="system-white"
    scrollMarginTop={scrollMarginTop}
  >
    {children}
  </Box>
))

const OfficeDetailSection = forwardRef<HTMLDivElement, PropsWithChildren<SectionProps>>(
  ({ children, landingContent, scrollMarginTop }, parentRef) => {
    const { landing, uid } = useDetailInfo()
    const boxRef = useRef<HTMLDivElement>(null)
    const ref = useCombinedRefs(boxRef, parentRef)
    const isDetailLoading = useOfficeFullDetailLoading(uid)
    const isMounted = useDeferredValue(useIsMounted())

    useEffect(() => {
      if (isNotNil(landingContent) && boxRef.current && landing === landingContent && !isDetailLoading && isMounted) {
        boxRef.current?.scrollIntoView()
      }
    }, [landing, landingContent, ref, isDetailLoading, isMounted])

    return (
      <SectionBox ref={ref} scrollMarginTop={scrollMarginTop}>
        {children}
      </SectionBox>
    )
  },
)

const LwhDetailSection = forwardRef<HTMLDivElement, PropsWithChildren<SectionProps>>(
  ({ children, landingContent, scrollMarginTop }, parentRef) => {
    const { landing, uid } = useDetailInfo()
    const boxRef = useRef<HTMLDivElement>(null)
    const ref = useCombinedRefs(boxRef, parentRef)
    const isDetailLoading = useLwhFullDetailLoading(uid)
    const isMounted = useDeferredValue(useIsMounted())

    useEffect(() => {
      if (isNotNil(landingContent) && boxRef.current && landing === landingContent && !isDetailLoading && isMounted) {
        boxRef.current?.scrollIntoView()
      }
    }, [landing, landingContent, ref, isDetailLoading, isMounted])

    return (
      <SectionBox ref={ref} scrollMarginTop={scrollMarginTop}>
        {children}
      </SectionBox>
    )
  },
)

const LandDetailSection = forwardRef<HTMLDivElement, PropsWithChildren<SectionProps>>(
  ({ children, landingContent, scrollMarginTop }, parentRef) => {
    const { landing, uid } = useDetailInfo()
    const { data: registrations } = usePnusRegistrations(uid)
    const hasRegistration = registrations?.page?.totalSize !== 0
    const boxRef = useRef<HTMLDivElement>(null)
    const ref = useCombinedRefs(boxRef, parentRef)
    const isDetailLoading = useLandFullDetailLoading(uid)
    const isMounted = useDeferredValue(useIsMounted())
    const { mapType } = useMapType()
    // TODO: 상세 요약에서도 자세히보기와 같이 landing을 통해 해당 탭으로 이동하는 로직 작업필요
    const shouldNavigateToRegistrationTab = mapType === 'registration' && hasRegistration

    useEffect(() => {
      if (
        isNotNil(landingContent) &&
        boxRef.current &&
        (landing === landingContent || shouldNavigateToRegistrationTab) &&
        !isDetailLoading &&
        isMounted
      ) {
        boxRef.current?.scrollIntoView()
      }
    }, [landing, landingContent, ref, isDetailLoading, isMounted])

    return (
      <SectionBox ref={ref} scrollMarginTop={scrollMarginTop}>
        {children}
      </SectionBox>
    )
  },
)

const DetailSection = forwardRef<HTMLDivElement, PropsWithChildren<SectionProps>>(
  ({ children, landingContent, scrollMarginTop }, parentRef) => {
    const { detailContent } = useDetailInfo()

    return (
      <SwitchCase
        value={detailContent}
        caseBy={{
          office: (
            <OfficeDetailSection ref={parentRef} landingContent={landingContent} scrollMarginTop={scrollMarginTop}>
              {children}
            </OfficeDetailSection>
          ),
          lwh: (
            <LwhDetailSection ref={parentRef} landingContent={landingContent} scrollMarginTop={scrollMarginTop}>
              {children}
            </LwhDetailSection>
          ),
          land: (
            <LandDetailSection ref={parentRef} landingContent={landingContent} scrollMarginTop={scrollMarginTop}>
              {children}
            </LandDetailSection>
          ),
        }}
      />
    )
  },
)

SectionBox.displayName = 'SectionBox'
OfficeDetailSection.displayName = 'OfficeDetailSection'
LwhDetailSection.displayName = 'LwhDetailSection'
LandDetailSection.displayName = 'LandDetailSection'
DetailSection.displayName = 'DetailSection'

export default DetailSection
