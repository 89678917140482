import { FC, useRef, useState } from 'react'
import Box from '~/components/Box/Box'
import LandSkeleton from '~/templates/place/detail/LandSkeleton'
import DetailSection from '~/templates/place/detail/DetailSection'
import { useTranslation } from 'next-i18next'
import { useGetLandDataV1AreaLandGet } from '~/libs/apis/data/area/area'
import StickyBox from '~/templates/StickyBox'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'
import Land from '~/templates/place/detail/land/Land'
import Trade from '~/templates/place/detail/trade/Trade'
import NavBar from '~/templates/place/detail/NavBar'
import { ButtonData } from '~/templates/place/detail/overview/OverviewButton'
import useMapFilterHandler from '~/templates/common/hooks/useMapFilterHandler'
import ScrollHighlightWrapperWithTab from '~/templates/ScrollHighlightWrapperWithTab'
import LandDetailTable from '~/templates/place/detail/building/LandDetailTable'
import BuildingCommonData from '~/templates/place/detail/building/BuildingCommonData'
import DetailScrollProvider from '~/templates/DetailScrollProvider'
import useSearchResultLand from '~/templates/common/hooks/useSearchResultLand'
import LandOverviewSection from '~/templates/place/detail/overview/LandOverviewSection'
import { useLandInfoDataV1GovLandGet } from '~/libs/apis/data/gov/gov'
import { formatOverHundredNumber, isArrayAndNotEmpty } from '~/libs/utils/format'
import { createPortal } from 'react-dom'
import StreetViewModal from '~/templates/place/detail/overview/StreetViewModal'
import LesseeInfo from '~/templates/place/detail/lessee/LesseeInfo'
import { useGetLandDetailsDataV1LandsDetailsGet } from '~/libs/apis/data/land/land'
import useInterestLand from '~/libs/hooks/useInterestLand'
import { DETAIL_NAVBAR_HEIGHT, DETAIL_STICKY_AREA_HEIGHT } from '~/libs/constants/place'
import RegistrationContent from '~/templates/place/detail/registration/RegistrationContent'
import DetailFooterButtons from '~/templates/place/detail/DetailFooterButtons'
import useLandFullDetailLoading from '~/templates/place/detail/useLandFullDetailLoading'
import Indicator from '~/components/Indicator/Indicator'
import usePnusRegistrations from '~/libs/hooks/usePnusRegistrations'

interface LandDetailProps {
  pnu: string
}
const TAB_GAP = 16
const scrollMarginTop = DETAIL_STICKY_AREA_HEIGHT + 'px'
const stickyAreaHeight = DETAIL_STICKY_AREA_HEIGHT + TAB_GAP

const LandDetail: FC<LandDetailProps> = ({ pnu }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: place } = useTranslation('place', { keyPrefix: 'place_term' })
  const { data, isLoading } = useGetLandDataV1AreaLandGet({ pnu }, { swr: { enabled: Boolean(pnu) } })
  const { data: landInfo, isLoading: isLandInfoLoading } = useLandInfoDataV1GovLandGet(
    { pnu },
    { swr: { enabled: Boolean(pnu) } },
  )
  const {
    data: landDetail,
    isLoading: isDetailLoading,
    isValidating,
  } = useGetLandDetailsDataV1LandsDetailsGet({ pnus: pnu }, { swr: { enabled: Boolean(pnu) } })
  const isUpdating = isDetailLoading || isValidating
  const currentLandDetail = landDetail?.data[0]
  const interestBuildingId = currentLandDetail?.interestBuildingId ?? null
  const { handleAddition, handleDeletion, stateInterestedId } = useInterestLand({
    pnu,
    interestBuildingId,
  })
  const isFullDetailLoading = useLandFullDetailLoading(pnu)
  const { detailType } = useDetailInfo()
  const isFull = detailType === 'full'
  const { closeLandDetail } = useMapFilterHandler()
  const { showLand, hide } = useFullDetailDialog()

  const [showStreetViewModal, setShowStreetViewModal] = useState(false)
  const toggleShowStreetViewModal = () => setShowStreetViewModal((prev) => !prev)

  useSearchResultLand()

  const buildingRef = useRef<HTMLDivElement>(null)
  const landRef = useRef<HTMLDivElement>(null)
  const lesseeRef = useRef<HTMLDivElement>(null)
  const tradeRef = useRef<HTMLDivElement>(null)
  const registrationRef = useRef<HTMLDivElement>(null)
  const sections = [buildingRef, landRef, lesseeRef, tradeRef, registrationRef]
  const { data: registrations } = usePnusRegistrations(pnu)
  const registrationCount = formatOverHundredNumber(registrations?.page.totalSize)

  const isLoadingData = isLoading || isLandInfoLoading

  if (isLoadingData) {
    return <LandSkeleton />
  }

  if (!data) {
    return null
  }

  const handleCloseClick = () => {
    return closeLandDetail()
  }

  const handleFullClick = () => {
    showLand(pnu)
  }

  const handleInterestButtonClick = (active?: boolean) => {
    if (active) {
      handleDeletion()
    } else {
      handleAddition()
    }
  }

  const buttonData: ButtonData[] = [
    {
      iconKey: 'streetview',
      label: place('street_view'),
      onClick: toggleShowStreetViewModal,
    },
    {
      iconKey: 'interest',
      label: place('bookmarked'),
      active: stateInterestedId !== null,
      onClick: !isUpdating ? handleInterestButtonClick : undefined,
    },
    {
      iconKey: 'close',
      label: t('close'),
      onClick: isFull ? hide : handleCloseClick,
    },
  ]

  const overview = (
    <>
      <StickyBox top={0} backgroundColor="system-white">
        <NavBar buttonData={buttonData} name={data.address} />
      </StickyBox>
      <LandOverviewSection data={data} />
    </>
  )

  const building = isFull ? <BuildingCommonData pnu={pnu} isCompletion /> : <LandDetailTable pnu={pnu} />
  const land = <Land pnu={pnu} />
  const lessee = <LesseeInfo pnu={pnu} type="land" />
  const trade = <Trade pnu={pnu} />
  const registration = <RegistrationContent pnu={pnu} address={currentLandDetail?.address ?? null} />

  const buildingSection = (
    <DetailSection key="building" ref={buildingRef} scrollMarginTop={scrollMarginTop}>
      {building}
    </DetailSection>
  )
  const landSection = (
    <DetailSection key="land" ref={landRef} landingContent="land" scrollMarginTop={scrollMarginTop}>
      {land}
    </DetailSection>
  )
  const lesseeSection = (
    <DetailSection key="lessee" ref={lesseeRef} landingContent="lessee" scrollMarginTop={scrollMarginTop}>
      {lessee}
    </DetailSection>
  )
  const tradeSection = (
    <DetailSection key="trade" ref={tradeRef} landingContent="trade" scrollMarginTop={scrollMarginTop}>
      {trade}
    </DetailSection>
  )
  const registrationSection = (
    <DetailSection
      key="registration"
      ref={registrationRef}
      landingContent="registration"
      scrollMarginTop={scrollMarginTop}
    >
      {registration}
    </DetailSection>
  )

  const hasLandInfo = isArrayAndNotEmpty(landInfo)

  const sectionElements = hasLandInfo
    ? [buildingSection, landSection, lesseeSection, tradeSection, registrationSection]
    : [buildingSection, lesseeSection, tradeSection, registrationSection]

  const contents = (
    <Box display="flex" flexDirection="column" backgroundColor="gray-100" gap="16px">
      {sectionElements}
    </Box>
  )

  const tabItems = hasLandInfo
    ? [t('building'), t('estate'), t('lessee'), t('trade'), `${t('registration')} ${registrationCount}`]
    : [t('building'), t('lessee'), t('trade'), `${t('registration')} ${registrationCount}`]

  return (
    <>
      <Indicator loading={isFullDetailLoading} />
      <DetailScrollProvider>
        <ScrollHighlightWrapperWithTab
          stickyAreaHeight={stickyAreaHeight}
          sections={sections}
          stickyArea={overview}
          tabItems={tabItems}
          tabTop={DETAIL_NAVBAR_HEIGHT}
          sectionContent={contents}
          testId="land-detail"
          showFooter
          autoFocus={isFull}
        />
        <DetailFooterButtons
          isExistsContactNumber={currentLandDetail?.isExistsContactNumber}
          onFullClick={handleFullClick}
        />
      </DetailScrollProvider>
      {showStreetViewModal &&
        createPortal(
          <StreetViewModal
            center={{ lat: data.centerLat, lng: data.centerLng }}
            boundary={data.areaPaths}
            onClose={toggleShowStreetViewModal}
          />,
          document.body,
        )}
    </>
  )
}

export default LandDetail
