import { useTranslation } from 'react-i18next'
import { Box } from '~/components'
import TitleDropdown from '~/components/Dropdown/TitleDropdown'
import Typography from '~/components/Typography/Typography'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import StickyBox from '~/templates/StickyBox'
import { useOfficeGroupDataV1BuildingsGroupGroupRaIdGet } from '~/libs/apis/data/buildings/buildings'
import { officeDetailDataV1BuildingsOfficeRaIdGet } from '~/libs/apis/data/buildings-office/buildings-office'
import { useAreaFormat } from '~/libs/hooks/useFormat'
import { isNotNil } from '@toss/utils'
import useMapFilterHandler from '~/templates/common/hooks/useMapFilterHandler'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'

interface BuildingGroupSectionProps {
  /** 건물 그룹 식별자 */
  groupRaId: string
}

/**
 * 건물 그룹 상세 영역
 */
const BuildingGroupSection = ({ groupRaId }: BuildingGroupSectionProps) => {
  const { t } = useTranslation('place', { keyPrefix: 'place_term' })
  const { t: group } = useTranslation('place', { keyPrefix: 'building_group' })
  const { uid: raId, detailType } = useDetailInfo()
  const { updateDialog } = useFullDetailDialog()
  const { selectBuilding } = useMapFilterHandler()
  const areaFormatter = useAreaFormat()
  const { data } = useOfficeGroupDataV1BuildingsGroupGroupRaIdGet(groupRaId)

  if (!data) {
    return
  }

  const formatGroupInfoLine = () => {
    const values: string[] = []

    if (isNotNil(data.totalArea) || isNotNil(data.platArea)) {
      values.push(
        `${areaFormatter(data.totalArea, { decimalRoundUnit: 'n2' })} (${t('plat')}${areaFormatter(data.platArea, { decimalRoundUnit: 'n2' })})`,
      )
    }

    if (isNotNil(data.mainPurpose)) {
      values.push(data.mainPurpose)
    }

    if (isNotNil(data.constructDateY)) {
      values.push(data.constructDateY)
    }

    return values.join(' · ')
  }

  const handleOnChange = (value: string) => {
    officeDetailDataV1BuildingsOfficeRaIdGet(value).then((res) => {
      if (res) {
        if (detailType === 'full') {
          updateDialog(res.raId)
        } else {
          selectBuilding(res.raId, res.propertyTypeCode, true)
        }
      }
    })
  }

  return (
    <>
      <Box display="flex" flexDirection="column" backgroundColor="system-white" p={5} gap={1}>
        <Typography variant="h3" fontWeight="bold" color="gray-800">
          {data.buildingName}
        </Typography>
        <Typography color="gray-800">{formatGroupInfoLine()}</Typography>
      </Box>
      <StickyBox top={56} px={5} pt={1} pb={5} backgroundColor="system-white">
        <TitleDropdown
          value={raId}
          hasChip
          onChange={handleOnChange}
          options={data.subBuildings.map((v, i) => ({
            chip: group('main_building_number', { number: i + 1 }),
            value: v.raId,
            label: v.buildingName,
          }))}
        />
      </StickyBox>
    </>
  )
}

export default BuildingGroupSection
