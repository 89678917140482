import { FC, useMemo } from 'react'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import MultiTemplate from '~/components/DataGrid/MultiTemplate'
import { formatDateYmd } from '~/libs/utils/date'
import { useTranslation } from 'next-i18next'
import { formatVacancyRate } from '~/libs/utils/format'
import useNilableValueAccessor from '~/components/DataGrid/hooks/useNilableValueAccessor'
import DataGrid from '~/components/DataGrid/DataGrid'
import { useAreaFormat } from '~/libs/hooks/useFormat'
import { PagerModel } from '@syncfusion/ej2-grids/src/pager/pager-model'

interface DemolishGridProps {
  dataSource: object[] | undefined
  pagerModel?: PagerModel
  onPageChange: (object: { currentPage: number }) => void
}

const DemolishGrid: FC<DemolishGridProps> = ({ dataSource, pagerModel, onPageChange }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const areaFormatter = useAreaFormat()

  const columns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'closureGubun',
        headerText: t('division'),
        textAlign: 'Left',
        width: 50,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: (_: any, data: any) => data.closureGubun ?? '-',
      },
      {
        field: 'closureDateYmd',
        headerText: t('closure_date'),
        textAlign: 'Left',
        width: 100,
        valueAccessor: (_: any, data: any) => formatDateYmd(data.closureDateYmd),
      },
      {
        field: 'buildingName',
        headerText: t('building_name'),
        textAlign: 'Left',
        width: 150,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'area',
        width: 100,
        textAlign: 'Left',
        headerTemplate: () => (
          <MultiTemplate isHeader content={[t('plat_area'), t('architecture_area')]} textAlign="left" />
        ),
        template: (props: any) => (
          <MultiTemplate
            content={[
              props.platArea ? areaFormatter(props.platArea, { decimalRoundUnit: 'n2' }) : undefined,
              props.archArea ? areaFormatter(props.archArea, { decimalRoundUnit: 'n2' }) : undefined,
            ]}
            textAlign="left"
          />
        ),
      },
      {
        field: 'totalArea',
        width: 100,
        textAlign: 'Left',
        headerTemplate: () => (
          <MultiTemplate isHeader content={[t('gra_py'), t('vl_rat_estm_tot_area')]} textAlign="left" />
        ),
        template: (props: any) => (
          <MultiTemplate
            content={[
              props.totalArea ? areaFormatter(props.totalArea, { decimalRoundUnit: 'n2' }) : undefined,
              props.vlRatEstmTotArea ? areaFormatter(props.vlRatEstmTotArea, { decimalRoundUnit: 'n2' }) : undefined,
            ]}
            textAlign="left"
          />
        ),
      },
      {
        field: 'rat',
        width: 80,
        textAlign: 'Left',
        headerTemplate: () => <MultiTemplate isHeader content={[t('bc_rat'), t('vl_rat')]} textAlign="left" />,
        template: (props: any) => (
          <MultiTemplate
            content={[
              props.bcRat ? formatVacancyRate(props.bcRat) : undefined,
              props.vlRat ? formatVacancyRate(props.vlRat) : undefined,
            ]}
            textAlign="left"
          />
        ),
      },
      {
        field: 'constructionDateYmd',
        width: 100,
        textAlign: 'Left',
        headerTemplate: () => (
          <MultiTemplate isHeader content={[t('permission_date'), t('start_construction_date')]} textAlign="left" />
        ),
        template: (props: any) => (
          <MultiTemplate
            content={[
              props.permissionConstructionDateYmd ? formatDateYmd(props.permissionConstructionDateYmd) : undefined,
              props.startConstructionDateYmd ? formatDateYmd(props.startConstructionDateYmd) : undefined,
            ]}
            textAlign="left"
          />
        ),
      },
      {
        field: 'useAprDateYmd',
        headerText: t('use_approval_date'),
        textAlign: 'Left',
        width: 100,
        valueAccessor: (_: any, data: any) => formatDateYmd(data.useAprDateYmd),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <DataGrid
      dataSource={dataSource}
      pagerModel={pagerModel}
      columns={columns}
      paginationMode="server"
      onPageChange={onPageChange}
    />
  )
}

export default DemolishGrid
